import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleService from '../../components/Service/SingleService';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// tech Icons
import techImg10 from '../../assets/img/technology/style2/10.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg16 from '../../assets/img/technology/style2/16.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg18 from '../../assets/img/technology/style2/18.svg';

// Service Icons
import imgMain42 from '../../assets/img/service/style2/main-img/42.png';
import imgMain46 from '../../assets/img/service/style2/main-img/46.png';
import imgMain47 from '../../assets/img/service/style2/main-img/47.png';
import imgMain48 from '../../assets/img/service/style2/main-img/48.png';
import imgMain30 from '../../assets/img/service/style2/main-img/30.png';


// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon24 from '../../assets/img/service/style2/main-img/24.png';
import serviceIcon32 from '../../assets/img/service/style2/main-img/32.png';
import serviceIcon45 from '../../assets/img/service/style2/main-img/45.png';


// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';


const AndroidAppDevelopment = () => {
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-50">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text gold-color"
                                subtitle="Make Yours Android App Differently"
                                titleClass="title pb-25"
                                title="Commence on a successful endeavor through exceptional Android Apps."
                                descClass="desc pb-5"
                                description="The best Android App developers in MetaQualt are your lifelong partners who ensure your business lifecycle won’t wither with time. We make it happen by delivering end-to-end Android services from ideation to maintenance. Our apps are made such that users don’t just flock to appealing platforms we make but generate some serious revenue. Our Sincere team is develop feature-rich, and hassle-free solutions using  Kotlin, Java, C, C++ HTML, and CSS. You get an affordable solution that works on all Android devices."
                                secondDescClass="desc pb-16"
                                secondDescription="Today’s top becomes tomorrow’s bottom. For this reason, we meet the ever-changing demands of trending technologies and keep tweaking your product. No matter how small your enterprise is, we transform your big dreams into reality. "
                            />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            {/* rs-technology-area-start */}
            <div className="rs-technology pt-30 pb-30">
                <div className="container">
                    <div className="row">            
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                    <a href="#">
                                        <div className="logo-img">
                                            <img src={techImg18} alt="" />
                                        </div>
                                    </a>
                                </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                    <a href="#">
                                        <div className="logo-img">
                                            <img src={techImg3} alt="" />
                                        </div>
                                    </a>
                                </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                <a href="#">
                                    <div class="logo-img">
                                        <img src={techImg4} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                <a href="#">
                                    <div class="logo-img">
                                        <img src={techImg8} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item pt-5 pb-5">
                                <a href="#">
                                    <div class="logo-img">
                                        <img src={techImg10} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="technology-item">
                                <a href="#">
                                    <div class="logo-img">
                                        <img src={techImg16} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-technology-area-start */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="Extend your hands to our tech spartans who eagerly trigger off your App development process with the best use of Android, React Native, Java, HTML, and Kotlin."
                        descClass="desc"
                        description="Do you Imagine benefiting from a long-standing Android used by millions of smartphone owners? With our services, you are on the right track! In Android app development, user experience is the name of the game. We are the game changers in the global market when it comes to product, design, modification, and revenue generation. Our developers hold superiority in the development of apps for various domains. Become a part of us. There is a whole world of possibilities for what you can do with what we develop for you."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain46}
                                    HoverImg={imgMain46}
                                    Title="Android App Development"
                                    Text="You get a custom Android app that’s feature-rich, customized, and highly engaging. We utilize the latest technologies and tools to make a flawless and scalable app. We strive for your growth which in turn makes us grow as the best App development service. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain42}
                                    HoverImg={imgMain42}
                                    Title="UI & UX Design Services"
                                    Text="Do you dream to woo your clients with the best first impression? Look no further. Hire our UI/UX crew for an immersive app experience. You get a killer combo of stunning design and seamless navigation resulting in massive customer engagement. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain47}
                                    HoverImg={imgMain47}
                                    Title="Quality Assurance and Testing"
                                    Text="Before you tap into the app we develop, it goes through vigorous debugging and emerges as a high-quality product. With us be rest assured that you get an error-free solution that gets stellar ratings for you and us."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain48}
                                    HoverImg={imgMain48}
                                    Title="Android Apps Support & Maintenance"
                                    Text="Say no to flings and join a team that avails themselves of the latest trends and keeps tweaking. You will not just get the next-level solution but we will make sure it upgrades and performs in the best possible way. You get the support that sticks around and fosters your app’s growth. Stay Calm! Your app is up-to-date with the latest trends"
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain30}
                                    HoverImg={imgMain30}
                                    Title="3rd Party Integrations "
                                    Text=" With our app, your business challenges aren’t a hard nut to crack anymore. Our 3rd Party integrations like payment integration, marketing tools, sales tools, and more will give you the confidence of a Hercules for your strategic business growth."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center"
                                    titleClass="title pb-10 fz-36"
                                    title="Professional Excellence Combined with Next-Gen Android Development"
                                    effectClass="heading-line"
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            {/* <Technologies /> */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-60"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="We innovate a powerful mobile app to pump up your business endeavors "
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="Watch the cocoon of your ideas grow into a butterfly of opportunities! No matter how big or small your idea is, our app development team gets cracking to turn it into a real and profitable mobile app. Your dream turns true with the assistance of our acclaimed app developers who use advanced technologies and provide 24/7 support. "
                    />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="100% Client Satisfaction"
                                Text="What helps you in turn helps us. The ground-breaking application we develop for you makes us a record-breaking company in the digital domain. When it comes to web or app development, our trusted support is preferred over many others in the industry. 
                                "
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon45}
                                Title="Expert Android App Developers" 
                                Text="Have ideas? Hire an incredible team of developers! We keep your specifications in our mind throughout the process of app development. Our diligent team extracts the power of renowned tools and technologies to develop and design an app that guarantees an optimal ROI. " 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon32}
                                Title="Advanced Tools & Technologies " 
                                Text="We value your precious time and hard-earned money. Our skilled developers use agile methods and advanced technologies to develop a cost-effective app in less time. We have got all the talent that makes the best Android app you would love to brag about.
                                " 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon24}
                                Title="24 X 7 Support & Assistance " 
                                Text="How about your app having a guardian who stays up day in and day out for improving client retention? Our 24 assistance & support is there for you always. Besides being developers. we are doctors that keep the bugs away and keep your app up and running all year long." 
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
            
            {/* rs-servicess-area-start */}
           {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
				ctaTitleClass="epx-title"
				ctaTitle="Reach out to the Dedicated Android Developers
                "
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, branding, web interface, and logo design to turn them into irresistible web apps. With our Android masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default AndroidAppDevelopment;