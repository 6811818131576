import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain7 from '../../assets/img/service/style2/main-img/7.png';
import imgMain8 from '../../assets/img/service/style2/main-img/8.png';
import imgMain9 from '../../assets/img/service/style2/main-img/9.png';
import imgMain11 from '../../assets/img/service/style2/main-img/11.png';
import imgMain12 from '../../assets/img/service/style2/main-img/12.png';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon15 from '../../assets/img/service/style2/main-img/15.png';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';


const phpDevelopment = () => {
    return (
        <React.Fragment>
            
        {/* banner section-start */}    
        <div className="rs-about pt-150 pb-50 md-pt-60">
            <div className="container">
                <div className="row pb-0">
                   
                    <div className="col-lg-6 col-md-12">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title2 mb-30"
                            subtitleClass="sub-text gold-color"
                            subtitle="YOU DREAM IT. WE MAKE IT HAPPEN!"
                            titleClass="title pb-25"
                            title="PHP Development Services that empower Websites and Businesses"
                            descClass="desc pb-5"
                            description="Is your website losing its charm, no matter how appealing it looks? Reach out to the right hands to build a trusted web application. Apart from a cool front end, your site needs a strong backend to stop time from withering away its optimal functionality."
                            secondDescClass="desc pb-16"
                            secondDescription="You will get certified PHP experts who will empower your site with a robust and long-standing PHP language. PHP is famed for its reliability, scalability, lightweight code, and ease of deployment. Our gurus in PHP frameworks such as Laravel, CodeIgniter, Symfony, PHPixie, Phalcon, CakePHP, Zend, and Yii frameworks band together to develop award-winning websites for you. Your website needs a potion of our PHP development service to catalyze its growth and performance to a remarkable extent. 
 
                            "
                        />
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                            <img className="main" src={bannerImg} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="shape-image">
                    <img className="top dance" src={effectImg2} alt="" />
                    <img className="bottom dance" src={effectImg3} alt="" />
                </div>
                {/* counter area start */}
                {/* <CounterHomeFive /> */}
                {/* counter area end */}
            </div>
        </div>
        {/* banner section-area-end */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="A PHP development service that amps up the speed and performance of your business."
                        descClass="desc"
                        description="Need a sturdy web application that generates profits? Knock on our door to let creativity and technology converge to form a solution that aligns with your brand, leaving a wide grin on your face. You get nothing but the finest PHP development service that elevates your website’s engagement and performance. We boast a diverse range of custom PHP development services."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain11}
                                    HoverImg={imgMain11}
                                    Title="Custom PHP Web Development"
                                    Text="If you need a web solution that’s unique to your specifications, our PHP web development is second to none. We let our PHP development portfolio speak for us. 
                                    Be it a 2-tier or 3-tier web application, our dedication pays off as you get a customized platform that facilitates your business expansion. Hire experts in both the old and new versions of PHP to develop stunning websites which linger in the end user’s mind.
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain7}
                                    HoverImg={imgMain7}
                                    Title="CMS Development"
                                    Text="Are you looking for a CMS Development service? Meet our trunk of PHP experts from which various content management systems stem. We exploit the power of PHP to scale your business and broaden your smile. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain8}
                                    HoverImg={imgMain8}
                                    Title="Portal Development Solutions
                                    "
                                    Text="Actualize your dreams with our business-oriented portals such as B2B, B2C, enterprise, corporate portals, and multi-tenant web platforms made using the most acclaimed PHP language."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain12}
                                    HoverImg={imgMain12}
                                    Title="Maintenance & Support"
                                    Text="“Develop-nurture-support-repeat” Our PHP development professionals use this tagline to motivate themselves and provide ongoing support to you. Doing this ensures your website remains up-to-date. We tweak, make changes and add essential elements to retain your site’s appeal and competitiveness."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-12 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain9}
                                    HoverImg={imgMain9}
                                    Title="API Development
                                    "
                                    Text="We create and integrate various APIs (public, private, and third-party) into your PHP system and CMS by utilizing the APIs' many interfaces."
                                    btnClass='d-none'
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            <Technologies />

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50 md-pt-80 md-pb-50">
                <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-46"
                            subtitleClass="sub-text gold-color"
                            subtitle="Process"
                            titleClass="title title"
                            title="Why Choose Us over a plethora of other companies for Development?
                            "
                            effectClass="heading-line" 
                            descClass="desc w-80"
                        />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Proven Expertise"
                                Text="PHP development is a toyhouse of our experts, where they play with various PHP 
                                frameworks to create a custom-built website. To give you a platform-specific user experience, our skilled programmers even hand-code many parts of your app. 
                                "
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="Customer-Centric" 
                                Text="You not only get a satisfying website, but we make sure to maintain, upgrade and support it proactively. As a result, “Customer-Centric” is what clients like you call us." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon15}
                                Title="Competitive Rate" 
                                Text="Turning your dreams into reality doesn’t need to cost you way too much. Our genius PHP web development team is well-equipped to handle challenging web projects across all niches at the most competitive costs. " 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon3}
                                Title="Dedicated Team" 
                                Text="We are your website’s mom! We love to develop, design, support, & foster your site and watch it grow. When it comes to web development, our motherly PHP team holds a record of whole-heartedly raising numerous websites using PHP expertise." 
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
            
             {/* rs-servicess-area-start */}
             
             {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-50"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Dedicated PHP development talents"
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, deployment, design, and support to turn them into irresistible web apps. With our PHP masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
            {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default phpDevelopment;