import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
// Service Icons
import imgMain40 from '../../assets/img/service/style2/main-img/40.png';
import imgMain41 from '../../assets/img/service/style2/main-img/41.png';
import imgMain42 from '../../assets/img/service/style2/main-img/42.png';
import imgMain43 from '../../assets/img/service/style2/main-img/43.png';
import imgMain28 from '../../assets/img/service/style2/main-img/28.png';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon15 from '../../assets/img/service/style2/main-img/15.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import techImg34 from '../../assets/img/technology/style2/34.svg';
import techImg35 from '../../assets/img/technology/style2/35.svg';
import techImg36 from '../../assets/img/technology/style2/36.svg';
import techImg37 from '../../assets/img/technology/style2/37.svg';
import techImg38 from '../../assets/img/technology/style2/38.svg';
import techImg9 from '../../assets/img/technology/style2/9.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
import ConsultNowBtn from '../../components/ConsultNowBtn';
import { CounterSection } from '../../components/CounterSection/Data';
import CounterSectioin from '../../components/CounterSection/CounterSectioin';
import { Feature_Item } from "../../components/CounterSection/Data";

const software_development = () => {
    let data = CounterSection.softwareDevelopment[0];
    const featureData = Feature_Item.softwareDevelopment;
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-7 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Marketing Experts"
                                titleClass="title pb-25"
                                title="Custom Software Development Solution

                                "
                                descClass="desc pb-5"
                                description="Metaqualt is a top software development company committed to assisting companies achieve growth and stay out in a competitive market with custom, innovative software solutions. Whether you are a new startup that needs dynamic software, a rapidly growing business looking for sophisticated digital ecosystem, or an established corporation looking to transform legacy systems, Metaqualt's customized software development services have been engineered to meet your unique business requirements."
                                
                            />
                             <ConsultNowBtn />
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            {/* rs-services-area-start */}
            < CounterSectioin data={data} featureItemsData={featureData}  />
            {/* rs-services-area-start */}


            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="Our Custom Software Development Services

                        "
                        descClass="desc"
                        description="Do you want to make software that will keep your start-up shining above all the rest? Meet Metaqualt's business analysts, project managers, and technical leads who will take you through every step so that the powerful, scalable software design reflects the best of your brand and upstages competition.
                        "
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 artificalint">
                        <div className="row">
                            <div className="col-lg-7  mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain40}
                                    HoverImg={imgMain40}
                                    Title="Enterprise Software Development "
                                    Text="Metaqualt is a high-end enterprise software development company that enacts smooth operations, increases efficiency, and enhances growth through custom software development. Our solutions integrate seamlessly with existing systems to make your business more efficient and operational."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-5 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain41}
                                    HoverImg={imgMain41}
                                    Title="Custom Web Application Development "
                                    Text="From simple web application development to highly complex web applications, we deliver responsive, user-friendly applications suited for your business. Metaqualt is the leader in creating high-quality, scalable, secure, and performance-focused web applications."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-5 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain42}
                                    HoverImg={imgMain42}
                                    Title="Mobile App Development "
                                    Text="Metaqualt develops full mobile app development services, be it Android or iOS. If you are looking to develop native or cross-platform apps, our team creates intuitive, engaging, and optimized mobile experiences that deliver exceptional performance. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-7 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain28}
                                    HoverImg={imgMain28}
                                    Title="Legacy Software Modernization "
                                    Text="Metaqualt is a leader in legacy software modernization. The scope of the service is the critical evaluation of existing software, definition of areas that needed improvement, and usage of modern technologies to refresh legacy systems up to relevant, efficient, and compatible today's business demands."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-7 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain43}
                                    HoverImg={imgMain43}
                                    Title="Startup Software Development"
                                    Text="Do you want to make software that will keep your start-up shining above all the rest? Meet Metaqualt's business analysts, project managers, and technical leads who will take you through every step so that the powerful, scalable software design reflects the best of your brand and upstages competition."
                                    btnClass='d-none'
                                  
                                />
                            </div>
                            <div className="col-lg-5 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain43}
                                    HoverImg={imgMain43}
                                    Title="Software Product Development"
                                    Text="Do you have a new software idea? Metaqualt provides full-cycle development of software products-from initial ideas and MVPs to testing and deployment. We help your innovative product ideas gain market-readiness as solutions of the highest standard for modern users. "
                                    btnClass='d-none'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}         

            {/* rs-services-area-start */}
            <Technologies />
            {/* rs-services-area-start */}           

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-46"
                            subtitleClass="sub-text style-bg"
                            subtitle="Process"
                            titleClass="title title"
                            title="Looking for Exceptional Software Development? We are here to help you. 
                            "
                            effectClass="heading-line" 
                            descClass="desc w-80"
                            description="
                           Metaqualt brings life to your dream for perfect and innovative software. Need a customized solution or full-scale application? Our software development team is committed to creating reliable, scalable, and high-performance software that will cater to your needs. Let us be your partner in converting complex requirements into fully functional applications.
                            "  
                        />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="From Concept to Code"
                                Text="From concept to seamless execution, we detail each line of code to ensure the software performs at its best. Our experience developers focus on every detail so that your software is not only as you expect but more than what you expected."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="User-First Development" 
                                Text="We put the user at the center of everything we do. From understanding the user journey, we deliver something intuitive, functional, and highly efficient-thought through for best-in-class user experience." 
                                />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon15}
                                Title="Innovative and Future-Proof Solutions" 
                                Text="We don't just build for today, but design and implement with tomorrow in mind. Using cutting-edge technologies and frameworks, our software is adaptable, scalable, and prepared for the challenges of tomorrow." 
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
            
             {/* rs-servicess-area-start */}
             {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 
            <div className="accordions pb-50">
        <div className="container">
          <FAQHeader data={faqDescriptions["software-development"]} />

          <AccordionCards data={accordions.software_development} />
        </div>
      </div>
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Our Software Development Experts and Witness a Game-Changer"
				ctaTextClass="exp-text"
				ctaText="Develop your innovative ideas into robust, scalable, and cutting-edge software solutions. From brainstorming to deployment, our software development maestros craft tailor-made applications that drive business growth, streamline processes, and bring your vision to life. With Metaqualt, you get solutions that maximize value, boost productivity, and deliver results that exceed expectations."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default software_development;