import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProjectTwo from '../../components/phpDevelopment/SingleProjectTwo';


import projectImg1 from '../../assets/img/project/laravel.jpg';
import projectImg2 from '../../assets/img/project/codeigniter.jpg';
import projectImg3 from '../../assets/img/project/cake-php.jpg';
import projectImg4 from '../../assets/img/project/Core-PHP.jpg';

import projectImg7 from '../../assets/img/bg/case-study.jpg';


const Technologies = () => {

    const options = {
        items: 4,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: true,
            },
            768:{
                items: 1,
                nav: true,
            },
            991: {
                items: 2,
                nav: true,
            },
            1200: {
                items: 3,
                nav: true,
            },
            1366: {
                items: 3,
                nav: true,
            },
            1600: {
                items: 4,
                nav: true,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-case-study pt-0 pb-0">
                <div className="">
                    <div className="row margin-0 align-items-center">
                        <div className="col-lg-4 col-md-6 padding-0 md-mb-50">
                            <div className="case-study" style={{ backgroundImage: `url(${projectImg7})` }}>
                                <SectionTitle
                                    sectionClass="sec-title2 mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Our Expertise"
                                    titleClass="title testi-title white-color pb-20"
                                    title="Pace up your Dreams with Our Ace PHP Frameworks."
                                    descClass="desc-big"
                                    description="You get
                                    the finest web applications made using a wide range of open-source
                                    frameworks, that include Laravel, Symphony, CakePHP, and more. MVC (Model View
                                    Controller) makes it easy for us to modify things.
                                    "
                                />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 padding-0">
                            <div className="case-study-wrap">
                                <div className="rs-project style3 modify1 mod md-pt-0">
                                    <OwlCarousel options={options} >
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg1} 
                                            Title="Laravel"
                                            Text="From small to large projects, Laravel is the PHP framework of choice for building web applications."
                                            projectURL="Laravel"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg2} 
                                            Title="Codeignitor"
                                            Text="Full-featured
                                            web apps on your mind? We provide you the most simplified yet gorgeous toolkit
                                            with the power of the PHP framework Codeigniter.
                                            " 
                                            projectURL="latform-integration"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg3} 
                                            Title="Cake PHP" 
                                            Text="Choosing
                                            CakePHP, you save the coding time and build a simple yet super fast web app
                                            that never fails to delight the end users. 
                                            " 
                                            projectURL="innovative-interfaces" 
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg4} 
                                            Title="Core PHP"
                                            Text="Even the
                                            basic things can make a big difference. Use the most basic PHP language, Core
                                            PHP, to craft the most dynamic web application.
                                            " 
                                            projectURL="product-engineering"
                                        />
                                       
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </React.Fragment>
	)
}

export default Technologies;