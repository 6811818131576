import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProjectTwo from '../../components/phpDevelopment/SingleProjectTwo';


import projectImg1 from '../../assets/img/project/P4.jpg';
import projectImg2 from '../../assets/img/project/P8.jpg';
import projectImg3 from '../../assets/img/project/P9.jpg';
import projectImg4 from '../../assets/img/project/P10.jpg';

import projectImg7 from '../../assets/img/bg/case-study.jpg';


const Technologies = () => {

    const options = {
        items: 4,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: true,
            },
            768: {
                items: 2,
                nav: true,
            },
            1200: {
                items: 3,
                nav: true,
            },
            1366: {
                items: 3,
                nav: true,
            },
            1600: {
                items: 4,
                nav: true,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-case-study pt-0 pb-0 txt-change">
                <div className="">
                    <div className="row margin-0 align-items-center">
                        <div className="col-xl-4 col-lg-5  col-sm-12 padding-0 md-mb-50">
                            <div className="case-study" style={{ backgroundImage: `url(${projectImg7})` }}>
                                <SectionTitle
                                    sectionClass="sec-title2 mb-30"
                                    subtitleClass="sub-text add-style"
                                    subtitle="Our Expertise"
                                    titleClass="title testi-title white-color pb-20"
                                    title="IT Consulting Services"
                                    descClass="desc-big"
                                    description="With our high quality IT consultancy services & business IT support services, we can support any size organization. With our top-notch IT consultancy services, we can help your organization develop advanced IT strategies & creative solutions."
                                />
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-sm-12 padding-0">
                            <div className="case-study-wrap">
                                <div className="rs-project style3 modify1 mod md-pt-0">
                                    <OwlCarousel options={options} >
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg1} 
                                            Title="Web Development"
                                            Text="We provide high quality web development services for a wide range of industries all over the world. A great team of Web Developers works for us in-house, who are adept in the latest technologies."
                                            projectURL="Laravel"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg2} 
                                            Title="Mobile App Development "
                                            Text="The latest & trendiest technologies are used to provide all types of Mobile App Development Services. Our team of Android, iOS & cross-platform mobile app developers is highly experienced & skilled." 
                                            projectURL="latform-integration"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg3} 
                                            Title="CMS & Framework" 
                                            Text="CMS & Frameworks are all supported by us. With a team of expert developers for WordPress, Laravel, Codeigniter & Magento, we are ranked as one of the best WordPress Development Companies." 
                                            projectURL="innovative-interfaces" 
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg4} 
                                            Title="Trending Solution"
                                            Text="We cover all latest technologies like Angular.JS, Vue.JS & Node.Js with our experienced & knowledgeable developers. We are quick learners & can easily adopt new technologies. Your choice of platform is up to you." 
                                            projectURL="product-engineering"
                                        />
                                       
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </React.Fragment>
	)
}

export default Technologies;