import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
const FAQHeader = (data) => {
  let Description = data.data.description;
  return (
    <>
      <SectionTitle
        sectionClass="sec-title2 mb-30 text-center"
        subtitleClass="sub-text style-bg"
        subtitle="Marketing Experts"
        titleClass="title pb-25"
        title="FAQs"
        descClass="desc w-80"
        description={Description} 
        effectClass="heading-line"
      />
     
    </>
  );
};

export default FAQHeader;