import React from "react";

const FeatureItem = ({ item }) => {
  return (
    
        <div className="col-xl-3 col-lg-6">
          <div className="counter_inner_sec">
            <div className="listar-feature_listar-feature_sec2">
              <div className="listar-feature_sec2">
                <div className="listar-feature-icon-inner">
                  <h2>{item.number}</h2>
                </div>
              </div>
              <div className="counter_sec_des">
                <h5>{item.title}</h5>
              </div>
            </div>
          </div>
        </div>
   
  );
};

export default FeatureItem;
