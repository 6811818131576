import React from 'react';
import BannerTwo from '../../components/Banner/BannerTwo';
import Service from './ServiceSection';
import About from './AboutSection';
import ServiceFlip from './ServiceSectionFlip';
import CallUs from './CallUsSection';
import Process from './ProcessSection';
import Project from '../../components/Project';
import Testimonial from './TestimonialSection';
// import Technology from '../../components/Technology';
import VideoStyleOne from '../../components/Video/VideoStyleOne';
import Blog from './BlogSection';
import CTA from '../../components/Common/CTA';
import Brand from '../../components/Common/Brand'
import ScrollToTop from '../../components/Common/ScrollTop';
import SectionTitle from '../../components/Common/SectionTitle';
import ServicesTabs from '../../components/Elements/Tab/Services';


const HomeThreeMain = () => {

	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerTwo />
			{/* banner-start */}			
			
			{/* About-area-start */}
			<About />
			{/* About-area-end */}

			{/* Service-area-start */}
			<Service />
			{/* Service-area-end */}

			{/* ServiceFlip-area-start */}
			<ServiceFlip /> 
			{/* ServiceFlip-area-end */}
			
			{/* project-area-start */}
			<Project />
			{/* project-area-end */}			
			
			{/* call us section start */}
			{/* <CallUs /> */}
			{/* call us section end */}	

			{/* Technology-area-start */}
			
			{/* Technology-area-end */}
			
			{/* process-area-start */}
			<Process />
			{/* process-area-end */}

			{/* newsletter-area-start */}
		
     
                  {/* brand-area-start */}
			<Brand />
			{/* brand-area-end */}
<div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-50"
                        subtitleClass="sub-text style-bg"
                        subtitle="TECHNOLOGY INDEX"
                        titleClass="title pb-10"
                        title="Technologies we adopt to scale our customer’s brand."
                        descClass="desc"
                        effectClass="heading-line"
                    />
                   <ServicesTabs />
                </div>
            </div> 
			
			  {/* <Technology /> */}
			{/* newsletter-area-end */}

			{/* VideoStyleOne area start */}
			{/* <VideoStyleOne /> */}
			{/* VideoStyleOne area end */}

			{/* Testimonial-area-start */}
			{/* <Testimonial /> */}
			{/* Testimonial-area-end */}

			{/* blog-area-start */}
			{/* <Blog /> */}
			{/* blog-area-end */}

			

			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-120 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Razor-Sharp alpha geeks for your web development project"
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, designing, deployment, and delivery to turn them into irresistible web apps. With our web app masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
          
			{/* scrolltop-start */}
			<ScrollToTop />
			
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeThreeMain;