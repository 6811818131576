import React from 'react';
// import BlogPart from '../../components/Blog/HomeBlogSection';
import SectionTitle from '../../components/Common/SectionTitle';

const Blog = () => {
    return (
        <React.Fragment>
            <div id="rs-blog" className="rs-blog pt-80 pb-50 md-pt-80 md-pb-50">
                <div className="container">                    
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Blogs"
                        titleClass="title"
                        title="Read Our Latest Tips & Tricks"
                        effectClass="heading-line"
                    />
                    {/* <BlogPart /> */}
                </div>
            </div>
        </React.Fragment>
    );
}
export default Blog;