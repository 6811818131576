import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
// Service Icons
import imgMain40 from '../../assets/img/service/style2/main-img/40.png';
import imgMain41 from '../../assets/img/service/style2/main-img/41.png';
import imgMain42 from '../../assets/img/service/style2/main-img/42.png';
import imgMain43 from '../../assets/img/service/style2/main-img/43.png';
import imgMain28 from '../../assets/img/service/style2/main-img/28.png';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon15 from '../../assets/img/service/style2/main-img/15.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import techImg34 from '../../assets/img/technology/style2/34.svg';
import techImg35 from '../../assets/img/technology/style2/35.svg';
import techImg36 from '../../assets/img/technology/style2/36.svg';
import techImg37 from '../../assets/img/technology/style2/37.svg';
import techImg38 from '../../assets/img/technology/style2/38.svg';
import techImg9 from '../../assets/img/technology/style2/9.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
import ConsultNowBtn from '../../components/ConsultNowBtn';

import { CounterSection } from '../../components/CounterSection/Data';
import CounterSectioin from '../../components/CounterSection/CounterSectioin';
import { Feature_Item } from "../../components/CounterSection/Data";
const CRM_Development = () => {
    let data = CounterSection.CRMDevelopment[0];
    const featureData = Feature_Item.CRMDevelopment;
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-7 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Marketing Experts"
                                titleClass="title pb-25"
                                title="Metaqualt CRM Development Solutions

                                "
                                descClass="desc pb-5"
                                description="Metaqualt is a top-rated CRM development company providing customized solutions for enriching the relationship between the customer and business or enabling sales by helping to form alliances that optimize interactions with customers or by integrating seamless systems across enterprise levels and much more through platforms like Salesforce, Odoo, or even Zoho."
                                
                            />
                             <ConsultNowBtn />
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            {/* rs-services-area-start */}
            < CounterSectioin data={data} featureItemsData={featureData}  />
            {/* rs-services-area-start */}


            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="Our CRM Development Services

                        "
                        descClass="desc"
                        description="des baki
                        "
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 artificalint">
                        <div className="row">
                            <div className="col-lg-7  mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain40}
                                    HoverImg={imgMain40}
                                    Title="Salesforce CRM Development "
                                    Text="Salesforce CRM is the ultimate one-stop solution for managing customer relationships, automating workflows, and giving personalized experiences. We specialize in customizing Salesforce CRM to fit your needs. It also makes the service more interactive for the customers and reduces operational inefficiency. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-5 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain41}
                                    HoverImg={imgMain41}
                                    Title="Odoo CRM Development "
                                    Text="Odoo- it's a powerful open source CRM which lets you centralize all activities of managing customers. Metaqualt creates flexible and scalable CRMs tailored to the demands of each business, be them sales automation or customer support, by using the modularity of the project.
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-5 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain42}
                                    HoverImg={imgMain42}
                                    Title="Zoho CRM Development "
                                    Text="Zoho CRM is a one-stop-shop sales, marketing, and support automation tool. Our specialists customize Zoho CRM to empower your sales teams, drive integrated customer service, and have insights to inform informed decision-making.
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-7 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain28}
                                    HoverImg={imgMain28}
                                    Title="CRM Integration Services "
                                    Text="We have broad experience in integrating your CRM with your other systems, be these the existing ERP systems, the marketing systems, email engines, or more. Whether internal or third-party-based solutions, provided integration services ensure flawless data flows coupled with a continually enhancing business operation."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-7 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain43}
                                    HoverImg={imgMain43}
                                    Title="Custom CRM Development"
                                    Text="Metaqualt provides custom CRM development services for businesses that require special needs. We make designs, development, deployment of unique systems for enhancing client relationships, sales process, and overall satisfaction."
                                    btnClass='d-none'
                                  
                                />
                            </div>
                            <div className="col-lg-5 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain43}
                                    HoverImg={imgMain43}
                                    Title="CRM Migration Services "
                                    Text="If migrating from one CRM to another is the need, Metaqualt offers smooth CRM migration services. We ensure that all your critical customer data, sales history, and reports are migrated with no loss, making it a smooth transition.
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}         

            {/* rs-services-area-start */}
            <Technologies />
            {/* rs-services-area-start */}           

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-46"
                            subtitleClass="sub-text style-bg"
                            subtitle="Process"
                            titleClass="title title"
                            title="Want your tastefully designed CRM? We can help. 
                            "
                            effectClass="heading-line" 
                            descClass="desc w-80"
                            description="
                           Metaqualt brings to life your powerful vision of a CRM solution through seamless integrations, specific features, and user-friendly interfaces. Our team provides the best solutions ensuring value productivity and combines both the complexities of customized CRMs and all-inclusive implementations.
                            "  
                        />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="From Consultation to Implementation"
                                Text="We start with comprehensive consultation with the client to know the client's requirements and understand the current business challenges so that from there we develop a solution, set up your chosen platform-Salesforce, Odoo, or Zoho-then implement it according to best practices. 
                                "
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon1}
                                Title="User-Centered Design" 
                                Text="We put your users at the center of our CRM development process. Developing CRM solutions with focus on the needs of your sales, support, and marketing teams is of paramount importance. This yields CRM solutions that will be easy to use, intuitive, and really improve collaboration among team members." 
                                />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon15}
                                Title="Scalable & Future-Proof Solutions" 
                                Text="We will design a CRM solution to grow with your business. We'll utilize the best technologies and frameworks such that the CRM solution will be set up to be in line with the future needs of your organization, yet to grow in tandem with your business so that it remains ahead of the competition." 
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
            
             {/* rs-servicess-area-start */}
             {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 
            <div className="accordions pb-50">
            <div className="container">
                <FAQHeader data={faqDescriptions["CRM-development"]} />

            <AccordionCards data={accordions.CRM_development} />
                </div>
            </div>
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Our CRM Experts to Transform Your Business "
				ctaTextClass="exp-text"
				ctaText="Develop a custom CRM system that can be made to strengthen relationships, simplify sales, and drive growth. Metaqualt's CRM development experts are waiting for your vision and ready to turn it into a reality by getting tailored solutions that fit perfectly into your operations. Be the ultimate CRM partner to help your business win and bring success. 
                "
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default CRM_Development;