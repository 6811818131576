// // Service Icons
// Service Icons
import imgMain40 from "../../assets/img/service/style2/main-img/40.png";
import imgMain41 from "../../assets/img/service/style2/main-img/41.png";
import imgMain42 from "../../assets/img/service/style2/main-img/42.png";
import imgMain43 from "../../assets/img/service/style2/main-img/43.png";
import imgMain28 from "../../assets/img/service/style2/main-img/28.png";

import projectImg1 from '../../assets/img/project/P4.jpg';
import projectImg2 from '../../assets/img/project/P8.jpg';
import projectImg3 from '../../assets/img/project/P9.jpg';
import projectImg4 from '../../assets/img/project/P10.jpg';

import projectImg7 from '../../assets/img/bg/case-study.jpg';

// Service Image Icons
import serviceIcon14 from "../../assets/img/service/style2/main-img/14.png";
import serviceIcon1 from "../../assets/img/service/style2/main-img/1.png";
import serviceIcon15 from "../../assets/img/service/style2/main-img/15.png";


export const servicesData = [
  {
    MainImg: imgMain40,
    HoverImg: imgMain40,
    Title: "Web Interface Design",
    Text: "Looking for a first impression like never before? You can impress end-users with a dedicated team of UI/UX designers, well-known for their revolutionary web designs. You get a team of experts who crafts an eye-pleasing website interface with intense research and a user-centric approach.",
    itemClass: "services-item light-purple-bg",
    btnClass: "d-none"
  },
  {
    MainImg: imgMain41,
    HoverImg: imgMain41,
    Title: "Responsive Design Development",
    Text: "We enable innovative ideas to dovetail creativity and flexibility. With our Responsive Design Development, you get the same jaw-dropping interface across a range of platforms.",
    itemClass: "services-item light-purple-bg",
    btnClass: "d-none"
  },
  {
    MainImg: imgMain42,
    HoverImg: imgMain42,
    Title: "Wireframes & Prototypes",
    Text: "We use Prototypes and Wireframes to test the design style 2 and framework. Then we go about crafting that attractive design that we promise to deliver.",
    itemClass: "services-item light-purple-bg",
    btnClass: "d-none"
  },
  {
    MainImg: imgMain28,
    HoverImg: imgMain28,
    Title: "Design Layout To HTML",
    Text: "We give ears to your design suggestions. If you come up with a design that matches your brand value, we are on board to transform it into a hassle-free HTML document.",
    itemClass: "services-item light-purple-bg",
    btnClass: "d-none"
  },
  {
    MainImg: imgMain43,
    HoverImg: imgMain43,
    Title: "Custom Logo Design & Branding",
    Text: "We adorn your brand’s value with a unique and adaptable logo. We consider branding the bedrock of any successful endeavor. We deliberately make a great impact on your potential customer with our branding services.",
    itemClass: "services-item light-purple-bg",
    btnClass: "d-none"
  }
];

 export const projectData = [
  {
    projectImage: projectImg1,
    Title: "Web Development",
    Text: "We provide high quality web development services for a wide range of industries all over the world. A great team of Web Developers works for us in-house, who are adept in the latest technologies.",
    projectURL: "Laravel",
    itemClass: "project-item"
  },
  {
    projectImage: projectImg2,
    Title: "Mobile App Development",
    Text: "The latest & trendiest technologies are used to provide all types of Mobile App Development Services. Our team of Android, iOS & cross-platform mobile app developers is highly experienced & skilled.",
    projectURL: "latform-integration",
    itemClass: "project-item"
  },
  {
    projectImage: projectImg3,
    Title: "CMS & Framework",
    Text: "CMS & Frameworks are all supported by us. With a team of expert developers for WordPress, Laravel, Codeigniter & Magento, we are ranked as one of the best WordPress Development Companies.",
    projectURL: "innovative-interfaces",
    itemClass: "project-item"
  },
  {
    projectImage: projectImg4,
    Title: "Trending Solution",
    Text: "We cover all latest technologies like Angular.JS, Vue.JS & Node.Js with our experienced & knowledgeable developers. We are quick learners & can easily adopt new technologies. Your choice of platform is up to you.",
    projectURL: "product-engineering",
    itemClass: "project-item"
  }
];

export const ItservicesData = [
  {
    serviceImage: serviceIcon14,
    Title: "Pixel-by-pixel, img-by-img",
    Text: "Your website feels and looks great as our industrious techies work dedicatedly. We analyze the future design time. Get your ideas designed by designers with a Midas touch!",
    itemClass: "services-item"
  },
  {
    serviceImage: serviceIcon1,
    Title: "User-Centered Design",
    Text: "What looks good and what suits you is what converts well. Keeping your end users on top, we skillfully blend design with usability and functionality.",
    itemClass: "services-item"
  },
  {
    serviceImage: serviceIcon15,
    Title: "Future-Centric Designs",
    Text: "With our design assistance, your website is a beauty with a brain. Our serious designers create a logical and technical art that’s bound to impress and convert like crazy.",
    itemClass: "services-item"
  }
];




  