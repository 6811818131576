import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain26 from '../../assets/img/service/style2/main-img/26.png';
import imgMain27 from '../../assets/img/service/style2/main-img/27.png';
import imgMain28 from '../../assets/img/service/style2/main-img/28.png';
import imgMain29 from '../../assets/img/service/style2/main-img/29.png';
import imgMain13 from '../../assets/img/service/style2/main-img/13.png';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon15 from '../../assets/img/service/style2/main-img/15.png';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
import ConsultNowBtn from '../../components/ConsultNowBtn';
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data"
import FAQHeader from "../../components/FAQHeader/FAQHeader";

import { CounterSection } from '../../components/CounterSection/Data';
import CounterSectioin from '../../components/CounterSection/CounterSectioin';
import { Feature_Item } from "../../components/CounterSection/Data";

const phpDevelopment = () => {
    let data = CounterSection.CMSDevelopment[0];
    const featureData = Feature_Item.CMSDevelopment;
    return (
        <React.Fragment>
            
             {/* <!-- banner section start --> */}
             <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="We are Different"
                                titleClass="title pb-25"
                                title="Get a unique CMS Solution to celebrate your digital triumphs. 
                                "
                                descClass="desc pb-5"
                                description="Wondering what success looks like using the best CMS platform?"
                                secondDescClass="desc pb-16"
                                secondDescription="Wait no more. Reach out to our team of expert CMS developers
                                 to get a website that performs well than your boss, looks better than your secretary, and generates sales like
                                  a money magnet. With excellent UI/UX and numerous plugins at your service, business scalability via CMS won’t be just a dream. We provide complete
                                   CMS Development Services, including Custom Website Design, Website Redesign, eCommerce Website Development with WooCommerce, Blog Website Design, CMS Website Maintenance,
                                   Theme Customization, Responsive Designs, Speed Optimization, Security, etc.
                                "
                            />
                             <ConsultNowBtn />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            < CounterSectioin data={data} featureItemsData={featureData}  />
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Develop Exceptional Web Experience"
                        titleClass="title pb-10"
                        title="We bring our years of expertise to the table to develop exceptional CMS website
                        "
                        descClass="desc"
                        description="There’s no limit to what we can do with our CMS development 
                        squad. You can choose our smart CMS gurus who can help you with developing plugins, 
                        themes, integrations, UI/UX designs, CMS maintenance, support & updates for all kinds of web applications.
                        "
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain29}
                                    HoverImg={imgMain29}
                                    Title="Custom CMS Development"
                                    Text="Every business has unique needs. Our team is aware of this
                                     and develops a solution that aligns with your brand values. We see to it that you never fail to 
                                     attract visitors, and provide them value when they dive into your site. With us, you always get a customized 
                                     companion that is packed with exciting plugins and features. Get set to scale your business! 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain28}
                                    HoverImg={imgMain28}
                                    Title="CMS Theme Development"
                                    Text="Get an elegant CMS theme exclusively made for your business, brand, or enterprise. We also make sure that our UI/UX design wows your clients.
                                     We love to embellish your online influence with custom CMS themes. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain27}
                                    HoverImg={imgMain27}
                                    Title="CMS Customization"
                                    Text="Whenever it’s a call for change, we bring the trendiest designs and fine-tune your site’s user experience. Get a new site or 
                                    just revamp the already existing CMS websites with interface and features like never before. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain26}
                                    HoverImg={imgMain26}
                                    Title="CMS Mobile App Development"
                                    Text="You can now bring the big game on small screens. We use Progressive Web App (PWA) technology to turn a CMS site into an easy-on-the-eye mobile app.
                                     We never compromise in giving the finest user experience on smartphones apart from the web. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain13}
                                    HoverImg={imgMain13}
                                    Title="CMS Support & Maintenance"
                                    Text="Do you want a team to not only develop the best solution
                                     but also nurture it with apt support and maintenance? Hire us to get comprehensive post-development support that maintains, upgrades, and secures your brand’s online entity. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center  p-10"
                                    titleClass="title fz-36"
                                    title="We'll Take Care Of The Technical Aspects Of CMS Development"
                                    effectClass="heading-line"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            {/* <Technologies /> */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="Why choose us over a plethora of developing agencies for CMS Web and Mobile Apps? 

                        "
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Proven Expertise"
                            Text="Our experts will create websites that promote you 24/7. We develop angular-based web and mobile apps that are unique to your brand. 
                            "
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Post Development Support" 
                            Text="When it comes to brand expansion, we have got your back as no living human does. We provide ongoing & proactive post-development support to catch up with your evolving business needs. 
                            " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon15}
                            Title="Competitive Rate" 
                            Text="You get what we brag about at a very reasonable rate. We never compromise in giving a high-quality CMS solution with trending features, and next-level user experience. 
                            " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="Dedicated Team" 
                            Text="Over the years, a multitude of brands and businesses have given us their preference and endorsement.
                             That’s how we like to grow and we would like you to be one of our satisfied clients. 

                            " 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
              {/* rs-servicess-area-start */}
           {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            <div className="accordions pb-50">
                <div className="container">
                    <FAQHeader data={faqDescriptions["CMS-Development"]} />

                    <AccordionCards data={accordions.CMSDevelopment} />
                </div>
            </div>

            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-50"
				ctaTitleClass="epx-title"
				ctaTitle="Hire excellent CMS developers
                "
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, designing, deployment, and delivery to turn them into irresistible CMS websites. With our web app masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision. 
                "
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}
                       
            {/* working-process-area-start */}
            {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default phpDevelopment;