import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import Brand from "../../components/Common/Brand";
import bannerImg from "../../assets/img/banner/data2.png";
import SingleProcess from "../../components/Process/SingleProcess";
import Technologies from "./Technologies";
import SingleServiceFour from "../../components/Service/SingleServiceFour";
import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
// Working Process Images
import processImage1 from "../../assets/img/process/1.png";
import processImage13 from "../../assets/img/process/13.png";
import processImage14 from "../../assets/img/process/14.png";
import processImage15 from "../../assets/img/process/15.png";

// Service Icons
import imgMain40 from "../../assets/img/service/style2/main-img/40.png";
import imgMain41 from "../../assets/img/service/style2/main-img/41.png";
import imgMain42 from "../../assets/img/service/style2/main-img/42.png";
import imgMain43 from "../../assets/img/service/style2/main-img/43.png";
import imgMain28 from "../../assets/img/service/style2/main-img/28.png";

// Service Image Icons
import serviceIcon14 from "../../assets/img/service/style2/main-img/14.png";
import serviceIcon1 from "../../assets/img/service/style2/main-img/1.png";
import serviceIcon15 from "../../assets/img/service/style2/main-img/15.png";

// Service Icons
import img2 from "../../assets/img/technology/style3/2.png";
import img3 from "../../assets/img/technology/style3/3.png";
import img11 from "../../assets/img/technology/style3/11.png";
import img13 from "../../assets/img/technology/style3/13.png";
import img16 from "../../assets/img/technology/style3/16.png";
import img15 from "../../assets/img/technology/style3/15.png";
import img14 from "../../assets/img/technology/style3/14.png";
import img17 from "../../assets/img/technology/style3/17.png";

import techImg34 from "../../assets/img/technology/style2/34.svg";
import techImg35 from "../../assets/img/technology/style2/35.svg";
import techImg36 from "../../assets/img/technology/style2/36.svg";
import techImg37 from "../../assets/img/technology/style2/37.svg";
import techImg38 from "../../assets/img/technology/style2/38.svg";
import techImg9 from "../../assets/img/technology/style2/9.svg";
import techImg8 from "../../assets/img/technology/style2/8.svg";
import techImg4 from "../../assets/img/technology/style2/4.svg";
import techImg3 from "../../assets/img/technology/style2/3.svg";

import effectImg2 from "../../assets/img/about/dotted-3.png";
import effectImg3 from "../../assets/img/about/shape3.png";

import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import CounterSectioin from "../../components/CounterSection/CounterSectioin";
import { CounterSection } from "../../components/CounterSection/Data";
import { Feature_Item } from "../../components/CounterSection/Data";

const Data_Science = () => {
  let data = CounterSection.dataScience[0];
  const featureData = Feature_Item.dataScience;
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-6 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="Marketing Experts"
                titleClass="title pb-25"
                title="Stay Ahead with Metaqualt's Advanced Data Science Services"
                descClass="desc pb-5"
                description="Metaqualt provides tailored, state-of-the-art data science consulting and solutions to meet the specific needs of your business. Our brilliant data scientists specialize in the appropriate application of state-of-the-art statistical techniques, advanced neural and non-neural networks, and varied machine learning models in order to deliver actionable insights."
              />
              {/* <div classsname="services-hero">
                <ul className="services-hero-inner">
                 <li>
                  <h2>15+</h2>
                  <h5>Data Scientists</h5>
                 </li>
                 <li>
                  <h2>20+ </h2>
                  <h5>Projects Delivered</h5>
                 </li>

                 <li>
                  <h2>90%</h2>
                  <h5>Talent Retention Rate</h5>
                 </li>
                 <li>
                  <h2>100%</h2>
                  <h5>Refund Policy </h5>
                 </li>

                </ul>
              </div> */}
              <ConsultNowBtn />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>
      {/* counter area start */}

      {/* <!-- banner section end --> */}

      {/* rs-services-area-start */}
      {/* rs-services-area-start */}

      <CounterSectioin data={data} featureItemsData={featureData} />

      {/* rs-servicess-area-start */}
      <div className="rs-process style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Our Expertise"
            titleClass="title pb-10"
            title="Unlock the Potential of Data Science with Metaqualt

                        "
            descClass="desc"
            description="Handling scattered information, skill gaps, and too many choices for tools can be pretty intimidating. Metaqualt's experienced data scientists break down these complexities, allowing businesses to make confident, data-driven decisions without a lot of hassle.
                        "
            effectClass="heading-line"
          />

          <div className="rs-services style3 modify2 pt-20 artificalint">
            <div className="row">
              <div className="col-lg-7  mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain40}
                  HoverImg={imgMain40}
                  Title="Data Collection and Pre-processing"
                  Text="We use web scraping, APIs, and cloud-based platforms to collect and clean data. Through databases such as MongoDB, PostgreSQL, and MySQL, we ensure your data is relevant, accurate, and prepared for analysis. 
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-5 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain41}
                  HoverImg={imgMain41}
                  Title="Data Visualization and Business Intelligence"
                  Text="We build intelligent dashboards using Tableau, Power BI, and Google Data Studio. Their dashboards bring complex data to life, enabling smarter and faster decision-making.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-5 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain42}
                  HoverImg={imgMain42}
                  Title="NLP"
                  Text="Using tools like NLTK, spaCy, and BERT, we provide solutions for sentiment analysis, chatbot development, language translation, and text-to-speech systems in order to transform complex data into user-friendly formats.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-7 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain28}
                  HoverImg={imgMain28}
                  Title="Data Strategy Consultation"
                  Text="Every business is different. With us, our data scientists work in close collaboration with you to deliver tailored data strategies that directly relate to your business objectives, ensuring maximum value out of your information."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-7 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain43}
                  HoverImg={imgMain43}
                  Title="Predictive Analytics and Machine Learning"
                  Text="We take historical data and convert it into actionable insights using industry-leading tools such as Python, Scikit-learn, TensorFlow, and PyTorch. These insights help businesses predict trends, run smooth operations, and make the right decisions."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-5 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain43}
                  HoverImg={imgMain43}
                  Title="Big Data Solutions"
                  Text="With Hadoop, Spark, and NoSQL, we craft customized big data solutions changing enormous datasets into workable insights to make better operational processes and comfortable customer experiences and strategic decisions.
                                    "
                  btnClass="d-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
      <Technologies />
      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
      <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Process"
            titleClass="title title"
            title="Want the Best Data Science Solutions? Your Experts are Here!. 
                            "
            effectClass="heading-line"
            descClass="desc w-80"
            description="
                            Our solutions will unleash your data's full potential. Ask for data science services that propel you toward the right insights and speed up decision-making. You're in the right place!
                            "
          />
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon14}
                Title="Processed to Perfection"
                Text="Let's help our professional experts transform your raw data into an ocean of insights."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon1}
                Title="Insight-Driven Strategies"
                Text="Our strategies, honed for your business needs, yield outcomes that are measurable. "
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon15}
                Title="Future-Ready Solutions"
                Text="At the core innovative, our solutions scale and help in giving the competitive edge to the business for sustainable success. 
                                "
              />
            </div>
          </div>
        </div>
      </div>
      {/* rs-services-area-start */}

      {/* rs-servicess-area-start */}
      {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
      {/* rs-services-area-start */}

      <div className="accordions pb-50">
        <div className="container">
          <FAQHeader data={faqDescriptions["data-science"]} />

          <AccordionCards data={accordions.data_science} />
        </div>
      </div>

      {/* newsletter-area-start */}
      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
        ctaTitleClass="epx-title"
        ctaTitle="Let Our Data Science Experts Hire You and Unleash the Power of Endless Possibilities"
        ctaTextClass="exp-text"
        ctaText="Unlock raw data into a hub of insights and innovation for solutions-driven growth, streamlined operations, and actualization of your business vision. This is made possible by using meta-analysis and predictive modeling with our customized strategies and bring value to the table through smarter decision-making, unparalleled value, and future-ready strategies with Metaqualt."
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      />
      {/* newsletter-area-end */}

      {/* working-process-area-start */}
      {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
      {/* working-process-area-end */}

      {/* brand-area-start */}
      {/* <Brand /> */}
      {/* brand-area-end */}
    </React.Fragment>
  );
};

export default Data_Science;
