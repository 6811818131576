import React from "react";
import { Link } from 'react-router-dom';

const ConsultNowBtn = () => {
  return (
    <div className="button-wrap text-left  md-center set_btnsize readon learn-more Consult btn-style510">
      <Link to="/contact" className="text-white">
        CONSULT NOW
      </Link>
    </div>
  );
};

export default ConsultNowBtn;
