import { Link } from 'react-router-dom';

const SingleServiceSix = (props) => {
    console.log("props",props);
	return(
        <div className={props.itemClass}>
            <div className="services-img">
                {/* <Link to={props.btnURL}> */}
                    <img 
                        className="main-img"
                        src={props.serviceImg} 
                        alt={props.Title}
                    />
                {/* </Link> */}
            </div>
            <div className="services-content">
                <div className="services-text">
                    <div className="services-title">
                        <h3 className="title">
                            {/* <Link to={props.btnURL}> */}
                                {props.Title}
                            {/* </Link> */}
                        </h3>
                        <p>{props.Text}</p>
                      
                    </div>
                </div>
                
                <div className={props.btnClass}>
                    <Link to={props.btnURL}>
                        {props.btnText}
                    </Link>
                </div>
            </div>
        </div>
	)
}

export default SingleServiceSix