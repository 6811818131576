import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceFlip from '../../components/Service/SingleServiceFlip';

import serviceIcon1 from '../../assets/img/service/main-home/softwere-devlop.png';
import serviceIcon2 from '../../assets/img/service/main-home/web-coding.png';
import serviceIcon3 from '../../assets/img/service/main-home/mobile-coding.png';
import serviceIcon4 from '../../assets/img/service/main-home/cms-system.png';
import serviceIcon5 from '../../assets/img/service/main-home/ux-ui.png';
import serviceIcon6 from '../../assets/img/service/main-home/soft-testing.png';

import shapeImg from '../../assets/img/service/s2.png';

const ServiceFlip = () => {

    return (
        <div id="rs-service" className="rs-services main-home style2 pt-100 pb-50 md-pt-80 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-50"
                    subtitleClass="sub-text style-bg"
                    subtitle="Services"
                    titleClass="title"
                    title="We Are a one-stop solution for a wide range of IT Solutions and Services."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon1}
                            serviceURL="javaScripts-development"
                            Title="Software Development"
                            TextDesc="We develop to generate sales and we design to impress users. You get nothing less than a tailor-made software programmed to expand your business globally. We fully leverage modern technologies and frameworks to let your choices and value do wonders. For a wide range of software development solutions hit us up! 
                            "
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon2}
                            serviceURL="./web-development"
                            Title="Web Development"
                            TextDesc="Are you a basement-dwelling nerd with a groundbreaking startup plan? Or are you a business organization wanting to elevate your business in the global market?  We are equipped with the wings of digital solutions in the form of the best web applications for you to fly high and influence your niche market."
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon3}
                            serviceURL="./mobile-app-development"
                            Title="Mobile App Development"
                            TextDesc="Watch your brand come to digital life with the best atomic principles of design and development. Just like a web app, a mobile app can be a platform behind your brand’s terrific growth. Our team emphasizes user experience and builds an artistic yet professional app for your brand."
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon4}
                            serviceURL="./cms-development"
                            Title="Open Source Development"
                            TextDesc="We can provide an open-source Development solution that you may use to manage your business website. Whether it’s WordPress, Joomla, or Drupal, you can influence your target visitors with outstanding designs and features at your disposal."
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon5}
                            serviceURL="./ux-ui-design"
                            Title="UI/UX Design"
                            TextDesc="Chat with us about your brand idea and we will craft a design that appeals to the end users. Count on us! You will get the most responsive design that enhances the user experience of your app/website in a way that will wow your target audience. "
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon6}
                            serviceURL="nodeJs-development"
                            Title="Software Testing"
                            TextDesc="You get a full-fledged web solution as a result of our Quality Assurance (QA) services for mobile, website, and cloud. We are responsible for effective Test Automation, Test Process Improvement, Security Testing, and Accessibility Testing."
                            ButtonClass="view-more"
                            ButtonText="Get In Touch"
                        />
                    </div>
                </div>
            </div>
            <div className="shape-animation">
                <div className="shape-part">
                    <img className="dance" src={shapeImg} alt="images" />
                </div>
            </div>
        </div>
    );
}

export default ServiceFlip;