import React from "react";
import { accordions } from "./Data";
import Cards from "./Cards";

const AccordianCards = (data) => {
  console.log(data.data);
  const AccordianCards_data = data.data;
  return <Cards data={AccordianCards_data} />;
};

export default AccordianCards;
