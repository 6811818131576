import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/laravel.png';
import aboutImg2 from '../../assets/img/about/php.png';
import aboutImg3 from '../../assets/img/about/angular.png';
import aboutImg4 from '../../assets/img/about/reactjs.png';
import aboutImg5 from '../../assets/img/about/javascript.png';


const AboutSlider = () => {
    const options = {
        items: 1,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 0,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        loop: true,
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
            768: {
                items: 1,
                nav: true,
            },
            992: {
                items: 1,
                nav: true,
            },
        }
    };

    return (
        <React.Fragment>
            <div className="rs-businessmodels style3 pt-80 pb-60" >
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-50"
                        subtitleClass="sub-text style-bg"
                        subtitle="Technologies We are working with"
                        titleClass="title pb-10 text-white"
                        title="Fulfill your Website Ideas with the most celebrated Web Development Technologies"
                        descClass="desc text-white"
                        effectClass="heading-line"
                    />
                    <OwlCarousel options={options} >
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg1}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 text-dark pb-16"
                                            title="Laravel Development"
                                            descClass="desc2 text-dark"
                                            description="Opt for Lavarel development services and open yourself to the great possibility of modernized websites. Our developers hold sway over Laravel development and provide top-notch Laravel apps. Reach out to us for Laravel development services that are highly beneficial, easy to use, and build attractive websites."
                                        />
                                        <ul className="check-square">
                                            <li className="text-dark">Enterprise Solution</li>
                                            <li className="text-dark">Web Application</li>
                                            <li className="text-dark">Mobile App Development</li>
                                            <li className="text-dark">E-commerce Development</li>
                                            <li className="text-dark">Maintenance And Support</li>
                                            <li className="text-dark">Custom Web Development</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg2}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 text-dark pb-16"
                                            title="PHP Development"
                                            descClass="desc2 text-dark"
                                            description="What makes a website surpass its rivals? Of course, its performance. We know the importance of PHP language in a website’s performance. We handle demanding PHP web development projects flawlessly. Our skilled developers create robust web apps by leveraging the power of PHP development. Your business is destined to not just sustain but rapidly grow in this evolving age of digitization. Connect with a crew of the best PHP developers offering all-inclusive PHP services from design, Prototyping, and theming to development, integration, and maintenance."
                                        />
                                        <ul className="check-square">
                                            <li className="text-dark">Full Cycle PHP Web Development</li>
                                            <li className="text-dark">CMS Development</li>
                                            <li className="text-dark">Themes and Plugins</li>
                                            <li className="text-dark">Maintenance & Support</li>
                                            <li className="text-dark">PHP Integration</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg3}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 text-dark pb-16"
                                            title="AngularJS Development"
                                            descClass="desc2 text-dark"
                                            description="We are known to be one of the leading angular.js development company that are inclined towards delivering customer-centric and top-notch services. Our expert developers possess hands-on experience in developing robust, scalable, flexible, and real-time applications"
                                        />
                                        <ul className="check-square">
                                            <li className="text-dark">Custom AngularJS Development</li>
                                            <li className="text-dark">Dynamic Web Application Development</li>
                                            <li className="text-dark">Angular Upgrade Services</li>
                                            <li className="text-dark">AngularJS App Support And Maintenance</li>
                                            <li className="text-dark">QA and Testing</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg4}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 text-dark pb-16"
                                            title="ReactJS Web Development"
                                            descClass="desc2 text-dark"
                                            description="Our expertise in ReactJS Development makes MindInventory one of the leading companies offering ReactJS Development services. With our ReactJS Developers, we can implement new technologies efficiently and turn them into solutions that generate business value. We use ReactJS to build powerful applications such as SPA's, PWA's, dynamic web pages, and social media apps. Expert ReactJS development services include:"
                                        />
                                        <ul className="check-square">
                                            <li className="text-dark">Custom ReactJS Web Development</li>
                                            <li className="text-dark">Interactive UI Development</li>
                                            <li className="text-dark">Web Application Development</li>
                                            <li className="text-dark">Enterprise Web Development</li>
                                            <li className="text-dark">Maintenance and Support</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='single-slider'>
                            <div className='row align-items-center'>
                                <div className='col-lg-5 md-mb-50'>
                                    <div className="image-part">
                                        <img
                                            src={aboutImg5}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-7 pl-50 md-pl-14'>
                                    <div className='slider-content'>
                                        <SectionTitle
                                            sectionClass="sec-title5 mb-20"
                                            titleClass="title title2 text-dark pb-16"
                                            title="JavaScript Development"
                                            descClass="desc2 text-dark"
                                            description="As a JavaScript development company, we provide all types of JavaScript development services for your business. A high-level, scalable, and reliable programming language, JavaScript is also known as JS. When JavaScript was first introduced, it was implemented only on client-side browsers. Nowadays, however, JS is emerging rapidly as a server-side technology and Node.JS is one of its best examples. As JavaScript can be used on both front-end and back-end, it is useful for developing single-page websites and mobile applications. "
                                        />
                                        <ul className="check-square text-white">
                                            <li className="text-dark">Front-end UI Development</li>
                                            <li className="text-dark">Backend Development</li>
                                            <li className="text-dark">Desktop App Development</li>
                                           <li className="text-dark">Maintenance and Support</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AboutSlider