import React from 'react';
import { Link } from 'react-router-dom';


const MenuItems = (props) => {
    const { parentMenu, secondParentMenu, activeMenu } = props;
    return (
        <React.Fragment>
           
            {/* <li className={parentMenu === '' ? 'current-menu-item' : ''}>
                <Link to="/" className={activeMenu === "/" ? "active-menu" : ""}>Main</Link>
            </li> */}


            <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link  className={activeMenu === "" ? "active-menu" : ""}>Services</Link>   
                <ul className="sub-menu submenu">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="main_menu_inner mega_menu_set">
                          <h4>Microsoft Tech</h4>
                          <ul>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Microsoft .NET dev</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Cloud Consulting </Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Application Migration </Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Application Integration</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Cloud Integration</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>SaaS Implementation </Link> </li>
                            </ul>
                        </div>
                    
                    </div>
                    
                    <div className="col-lg-3">
                        <div className="main_menu_inner mega_menu_set">
                          <h4>BI & Data analytics</h4>
                          <ul>
                          <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Power BI</Link> </li>
                            <li><Link to="" lassName={activeMenu === "" ? "active-menu" : ""}>SSIS</Link> </li>
                            <li><Link to="" lassName={activeMenu === "" ? "active-menu" : ""}>SSRS</Link> </li>
                            <li><Link to="" lassName={activeMenu === "" ? "active-menu" : ""}><span>Tableau</span></Link> </li>
                            <li><Link to="" lassName={activeMenu === "" ? "active-menu" : ""}>Database Management</Link> </li>
                            <li><Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>Database Performance Tuning</Link> </li>
                            </ul>
                        </div>                    
                    </div>
                    <div className="col-lg-3">
                        <div className="main_menu_inner mega_menu_set">
                          <h4>Modern Technology</h4>
                          <ul>
                            <li><Link to="/artificial-intelligence" lassName={activeMenu === "/artificial-intelligence" ? "active-menu" : ""}>Artificial Intelligence (AI)</Link> </li>
                            <li><Link to="/machine_learning" lassName={activeMenu === "/machine_learning" ? "active-menu" : ""}>Machine Learning</Link> </li>
                            <li><Link to="/data-science" lassName={activeMenu === "/data-science" ? "active-menu" : ""}>Data Science</Link> </li>
                            <li><Link to="/data-science" lassName={activeMenu === "/data-science" ? "active-menu" : ""}>BlockChain</Link> </li>
                            </ul>
                            <h4 className="pt-3">Digital Transformation</h4>
                          <ul>
                            <li><Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>UI/UX Design</Link></li>
                            <li><Link to="/digitl-market" lassName={activeMenu === "/digitl-market" ? "active-menu" : ""}>Digital Marketing</Link> </li>
                            <li><Link to="/quality_engineering" lassName={activeMenu === "/quality_engineering" ? "active-menu" : ""}>Quality Engineering</Link> </li>
                            </ul>
                        </div>
                    
                    </div>
                    <div className="col-lg-3">
                        <div className="main_menu_inner">
                          <h4>Application Development</h4>
                          <ul>
                          <li><Link to="/web-development" lassName={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link> </li>
                            <li><Link to="/softwaredevelopment" lassName={activeMenu === "/softwaredevelopment" ? "active-menu" : ""}>Software Development</Link> </li>
                            <li><Link to="/mobile-app-development" lassName={activeMenu === "/mobile-app-development" ? "active-menu" : ""}>Mobile App Development</Link> </li>
                            <li><Link to="/cms-development" lassName={activeMenu === "/cms-development" ? "active-menu" : ""}>CMS Development</Link> </li>
                            <li><Link to="/crm_development" lassName={activeMenu === "/crm_development" ? "active-menu" : ""}>CRM Development</Link> </li>
                            <li><Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>e-commerce Development</Link> </li>
                            </ul>
                        </div>
                        
                    
                    </div>
                </div>


                    {/* <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                        <Link to="/web-development" className={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/php-development" className={activeMenu === "/php-development" ? "active-menu" : ""}>PHP Development</Link>
                            </li>
                            <li>
                                <Link to="/laravel-development" className={activeMenu === "/laravel-development" ? "active-menu" : ""}>Laravel Development</Link>
                            </li>
                            <li>
                                <Link to="/codeigniter-development" lassName={activeMenu === "/codeigniter-development" ? "active-menu" : ""}>Codeigniter Development</Link>
                            </li>
                            <li>
                                <Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>UX/UI Design Development</Link>
                            </li>
                        </ul>
                    </li> */}
                    {/* <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                       <Link to="/mobile-app-development" lassName={activeMenu === "/mobile-app-development" ? "active-menu" : ""}>Mobile App Development</Link>   
                        <ul className="sub-menu">
                            <li>
                                <Link to="/android-app-development" lassName={activeMenu === "/android-app-development" ? "active-menu" : ""}>Android App Development</Link>
                            </li>
                            <li>
                                <Link to="/ios-app-development" lassName={activeMenu === "/ios-app-development" ? "active-menu" : ""}>iOS App Development</Link>
                            </li>
                        </ul>
                    </li> */}
                    {/* <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                       <Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>CMS Development</Link>   
                    </li> */}


                    {/* <ul className="sub-menu"> */}
                            {/* <li>
                            <Link to="/cms-development" lassName={activeMenu === "/wordpress-development" ? "active-menu" : ""}>CMS Development</Link>
                            </li> */}
                            
                            {/* <li>
                                <Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>eCommerce Development</Link>
                            </li> */}
                        {/* </ul> */}
                    {/* <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                       <Link to="/front-end" lassName={activeMenu === "/front-end" ? "active-menu" : ""}>Frontend Development</Link>   
                      
                    </li>   */}
                </ul>
            </li>
            {/* <li className={parentMenu === 'solution' ? 'current-menu-item' : ''}>
                <Link to="/solution" className={activeMenu === "/solution" ? "active-menu" : ""}>Solution</Link>
            </li> */}
            
            <li className={parentMenu === 'solution' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link  className={activeMenu === "" ? "active-menu" : ""}>Solution</Link>
                <ul className="sub-menu submenu">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="main_menu_inner mega_menu_set">
                        <h4>By Business Scales</h4>
                          <ul>
                            <li><Link to="/startup-solutions" lassName={activeMenu === "/startup-solutions" ? "active-menu" : ""}>Startup Solutions</Link> </li>
                            <li><Link to="/enterprise-solution" lassName={activeMenu === "/enterprise-solution" ? "active-menu" : ""}>Enterprise Solution</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>SME</Link> </li>
                            </ul>
                        
                        </div>


                    </div>
                    <div className="col-lg-3">
                        <div className="main_menu_inner mega_menu_set">
                        <h4>Custom Solutions</h4>
                          <ul>
                            <li><Link to="/devops_Solutions" lassName={activeMenu === "/devops_Solutions" ? "active-menu" : ""}>DevOps Solutions</Link> </li>
                            <li><Link to="/Cloud_Solutions" lassName={activeMenu === "/Cloud_Solutions" ? "active-menu" : ""}>Cloud Solutions</Link> </li>
                            <li><Link to="/OndemandBusinessSolutions" lassName={activeMenu === "/OndemandBusinessSolutions" ? "active-menu" : ""}>On-demand Business Solutions</Link> </li>
                            </ul>
                        </div>
                    
                    </div>
                    <div className="col-lg-3">
                        <div className="main_menu_inner mega_menu_set">
                         <h4>Industry Expertise</h4>
                          <ul>
                            <li><Link to="/business-app-solution" lassName={activeMenu === "/business-app-solution" ? "active-menu" : ""}>Retail/Business </Link> </li>
                            <li><Link to="/fitness-wellness-app-solutions" lassName={activeMenu === "/fitness-wellness-app-solutions" ? "active-menu" : ""}>Fitness App </Link> </li>
                            <li><Link to="/fintech-app-solution" lassName={activeMenu === "/fintech-app-solution" ? "active-menu" : ""}>Fintech-App </Link> </li>
                            <li><Link to="/travel-app-solutions" lassName={activeMenu === "/travel-app-solutions" ? "active-menu" : ""}>Travel Tourism</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Hospitality</Link></li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>e-commerce</Link> </li>
                            <li><Link to="/real-estate-app-solution" lassName={activeMenu === "/real-estate-app-solution" ? "active-menu" : ""}>Real Estate </Link> </li>
                            <li><Link to="/healthcare-app-solution" lassName={activeMenu === "/healthcare-app-solution" ? "active-menu" : ""}>Healthcare</Link> </li>
                            <li><Link to="/social-media-app" lassName={activeMenu === "/social-media-app" ? "active-menu" : ""}>Acution</Link> </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="main_menu_inner">
                         <h4>Salesforce Development</h4>
                          <ul>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Salesforce Consulting</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Salesforce Implementation </Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Salesforce Lightning </Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>AppExchange</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Sales Cloud</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>






                    {/* <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                        <Link to="/web-development" className={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/php-development" className={activeMenu === "/php-development" ? "active-menu" : ""}>PHP Development</Link>
                            </li>
                            <li>
                                <Link to="/laravel-development" className={activeMenu === "/laravel-development" ? "active-menu" : ""}>Laravel Development</Link>
                            </li>
                            <li>
                                <Link to="/codeigniter-development" lassName={activeMenu === "/codeigniter-development" ? "active-menu" : ""}>Codeigniter Development</Link>
                            </li>
                            <li>
                                <Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>UX/UI Design Development</Link>
                            </li>
                        </ul>
                    </li> */}
                    {/* <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                       <Link to="/mobile-app-development" lassName={activeMenu === "/mobile-app-development" ? "active-menu" : ""}>Mobile App Development</Link>   
                        <ul className="sub-menu">
                            <li>
                                <Link to="/android-app-development" lassName={activeMenu === "/android-app-development" ? "active-menu" : ""}>Android App Development</Link>
                            </li>
                            <li>
                                <Link to="/ios-app-development" lassName={activeMenu === "/ios-app-development" ? "active-menu" : ""}>iOS App Development</Link>
                            </li>
                        </ul>
                    </li> */}
                    {/* <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                       <Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>CMS Development</Link>   
                    </li> */}


                    {/* <ul className="sub-menu"> */}
                            {/* <li>
                            <Link to="/cms-development" lassName={activeMenu === "/wordpress-development" ? "active-menu" : ""}>CMS Development</Link>
                            </li> */}
                            
                            {/* <li>
                                <Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>eCommerce Development</Link>
                            </li> */}
                        {/* </ul> */}
                    {/* <li className={parentMenu === 'service' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                       <Link to="/front-end" lassName={activeMenu === "/front-end" ? "active-menu" : ""}>Frontend Development</Link>   
                      
                    </li>   */}
                </ul>
            </li>
            <li className={parentMenu === 'hire-dedicated-developers' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link  className={activeMenu === "" ? "active-menu" : ""}>Hire offshore developers</Link>
                

                <ul className="sub-menu submenu">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="main_menu_inner mega_menu_set">
                                <h4>mobileappdevelopers</h4>
                                <ul>
                                    <li><Link to="/hire-flutter-developers" lassName={activeMenu === "/hire-flutter-developers" ? "active-menu" : ""}>Flutter Developers</Link> </li>
                                    <li><Link to="/hire-react-native-developers" lassName={activeMenu === "/hire-react-native-developers" ? "active-menu" : ""}>React Native Developers</Link> </li>
                                    <li><Link to="/hire-ios-app-developer" lassName={activeMenu === "/hire-ios-app-developer" ? "active-menu" : ""}>iOS Developers</Link></li>
                                    <li><Link to="/hire-android-app-developer" lassName={activeMenu === "/hire-android-app-developer" ? "active-menu" : ""}>Android Developers</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="main_menu_inner mega_menu_set">
                            <h4>Full Stack Developers</h4>
                                <ul>
                                    <li><Link to="/hire-mean-stack-developers" lassName={activeMenu === "/hire-mean-stack-developers" ? "active-menu" : ""}>MeanStack Developers</Link> </li>
                                    <li><Link to="/hire-php-developers" lassName={activeMenu === "/hire-php-developers" ? "active-menu" : ""}>PHP Developers</Link> </li>
                                    <li><Link to="/hire-python-developers" lassName={activeMenu === "/hire-python-developers" ? "active-menu" : ""}>Python Developers</Link> </li>
                                    <li><Link to="/hire-nodejs-developers" lassName={activeMenu === "/hire-nodejs-developers" ? "active-menu" : ""}>Node.js Developers</Link> </li>
                                    <li><Link to="/hire-angular-developer" lassName={activeMenu === "/hire-angular-developer" ? "active-menu" : ""}>AngularJs Developers</Link> </li>
                                    <li><Link to="/hire-vuejs-developer" lassName={activeMenu === "/hire-vuejs-developer" ? "active-menu" : ""}>Vue Developers</Link> </li>
                                    <li><Link to="/hire-reactjs-developers" lassName={activeMenu === "/hire-reactjs-developers" ? "active-menu" : ""}>ReactJs Developers</Link> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="main_menu_inner mega_menu_set">
                            <h4>functional resources</h4>
                            <ul>
                                <li><Link to="/business_analyst" lassName={activeMenu === "/business_analyst" ? "active-menu" : ""}>business analyst</Link></li>
                                <li><Link to="/project_managers" lassName={activeMenu === "/project_managers" ? "active-menu" : ""}>project managers</Link> </li>
                                <li><Link to="/software-testers" lassName={activeMenu === "/software-testers" ? "active-menu" : ""}>software testers</Link> </li>
                                <li><Link to="/Hire-designers"  lassName={activeMenu === "/Hire-designers" ? "active-menu" : "" }> Hire designers</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="main_menu_inner">
                            <h4>experts in</h4>
                                <ul>
                                <li><Link to="/Crypto" lassName={activeMenu === "Crypto" ? "active-menu" : ""}>Crypto</Link></li>
                                <li><Link to="/Blockcain" lassName={activeMenu === "/Blockcain" ? "active-menu" : ""}> Blockchain</Link> </li>
                                <li><Link to="/Fintech" lassName={activeMenu === "/Fintech" ? "active-menu" : ""}>Fintech</Link> </li>
                                <li><Link to="/hire-ux-ui-developers" lassName={activeMenu === "/hire-ux-ui-developers" ? "active-menu" : ""}>UI/UX Devloper</Link></li>
                                <li><Link to="/Salesforce" lassName={activeMenu === "/Salesforce" ? "active-menu" : ""}>Salesforce</Link></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </ul>
            </li>
            <li className={parentMenu === 'about' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link className={activeMenu === "" ? "active-menu" : ""}>Company</Link>
                <ul className="sub-menu submenu">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="main_menu_inner mega_menu">
                            <h4>About metaqualt</h4>
                            <ul>
                                <li><Link to="/about" lassName={activeMenu === "/about" ? "active-menu" : ""}>About us</Link> </li>
                                <li><Link to="/Our_Achievement" lassName={activeMenu === "/Our_Achievement" ? "active-menu" : ""}>Our Achievement</Link> </li>
                                <li><Link to="/our_leading_metaqualt" lassName={activeMenu === "/our_leading_metaqualt" ? "active-menu" : ""}>Leadership Team</Link> </li>
                                <li><Link to="/Client-Testimonials" lassName={activeMenu === "/Client-Testimonials" ? "active-menu" : ""}>Client Testimonials</Link> </li>
                                <li><Link to="/contact" lassName={activeMenu === "/Client-Testimonials" ? "active-menu" : ""}>Contact Us</Link> </li>
                                </ul>
                            </div>
                        
                        </div>
                        <div className="col-lg-4">
                            <div className="main_menu_inner mega_menu">
                            <h4>Careers</h4>
                            <ul>
                                <li><Link to="/Current_openings" lassName={activeMenu === "/Current_openings" ? "active-menu" : ""}>Current Openings</Link> </li>
                                <li><Link to="/why_Join_us" lassName={activeMenu === "/why_Join_us" ? "active-menu" : ""}> Why Join Us</Link> </li>
                                <li><Link to="/Employee_Rewards" lassName={activeMenu === "/Employee_Rewards" ? "active-menu" : ""}> Employee Rewards</Link> </li>
                                <li><Link to="/life_metaqualt" lassName={activeMenu === "/life_metaqualt" ? "active-menu" : ""}>life at metaqualt</Link> </li>
                                </ul>
                            </div>
                        
                        </div>
                        <div className="col-lg-4">
                            <div className="main_menu_inner">
                            <h4>Data Mining</h4>
                            <ul>
                            <li><Link to="/blog" lassName={activeMenu === "/blog" ? "active-menu" : ""}>Blog</Link></li>
                            <li><Link to="/hire-flutter-developers" lassName={activeMenu === "/hire-flutter-developers" ? "active-menu" : ""}>   Videos</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>podcasts</Link></li>
                
                                </ul>
                            </div>
                        
                        </div>
                       
                    </div>
                </ul>
               
            </li>
            <li className={parentMenu === 'Portfolio' ? 'current-menu-item' : ''}>
                <Link to="/Portfolio" className={activeMenu === "/Portfolio" ? "active-menu" : ""}>Portfolio</Link>
            </li>
            <Link className='getTouch' to="/contact" >Get In Touch</Link>  

        </React.Fragment>
    );
}
export default MenuItems;