// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import SectionTitle from '../../components/Common/SectionTitle';

import techImg1 from '../../assets/img/technology/style2/1.svg';
import techImg2 from '../../assets/img/technology/style2/2.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg5 from '../../assets/img/technology/style2/5.svg';
import techImg6 from '../../assets/img/technology/style2/6.svg';
import techImg7 from '../../assets/img/technology/style2/7.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg9 from '../../assets/img/technology/style2/9.svg';
import techImg10 from '../../assets/img/technology/style2/10.svg';
import techImg11 from '../../assets/img/technology/style2/11.svg';
import techImg12 from '../../assets/img/technology/style2/12.svg';
import techImg13 from '../../assets/img/technology/style2/13.svg';
import techImg14 from '../../assets/img/technology/style2/14.svg';
import techImg15 from '../../assets/img/technology/style2/15.svg';
import techImg16 from '../../assets/img/technology/style2/16.svg';
import techImg17 from '../../assets/img/technology/style2/17.svg';
import techImg18 from '../../assets/img/technology/style2/18.svg';
import techImg19 from '../../assets/img/technology/style2/19.svg';
import techImg20 from '../../assets/img/technology/style2/20.svg';
import techImg21 from '../../assets/img/technology/style2/21.svg';
import techImg22 from '../../assets/img/technology/style2/22.svg';
import techImg23 from '../../assets/img/technology/style2/23.svg';
import techImg24 from '../../assets/img/technology/style2/24.svg';
import techImg25 from '../../assets/img/technology/style2/25.svg';
import techImg26 from '../../assets/img/technology/style2/26.svg';
import techImg27 from '../../assets/img/technology/style2/27.svg';
import techImg28 from '../../assets/img/technology/style2/28.svg';
import techImg29 from '../../assets/img/technology/style2/29.svg';
import techImg30 from '../../assets/img/technology/style2/30.svg';
import techImg31 from '../../assets/img/technology/style2/31.svg';
import techImg32 from '../../assets/img/technology/style2/32.svg';
import techImg33 from '../../assets/img/technology/style2/33.svg';



const Technology = (props) => {
    return (
        <div className="rs-technology gray-bg pt-50 pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text style-bg"
                    subtitle="Technology Index"
                    titleClass="title title"
                    title="What Technology We Are Using For Our Valued Customers"
                    effectClass="heading-line"
                />
                <ul class="nav nav-pills mb-3 justify-content-center justify-content-lg-start" id="pills-tab" role="tablist">
                 <li class="nav-item" role="presentation">
                   <button class="nav-link active" id="pills-front-tab" data-toggle="pill" data-target="#pills-front" type="button" role="tab" aria-controls="pills-front" aria-selected="true">Front-end</button>
                </li>
                <li class="nav-item" role="presentation">
                   <button class="nav-link" id="pills-back-tab" data-toggle="pill" data-target="#pills-back" type="button" role="tab" aria-controls="pills-back" aria-selected="false">Back-end</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-mob-tab" data-toggle="pill" data-target="#pills-mob" type="button" role="tab" aria-controls="pills-mob" aria-selected="false">Mobile Development</button>
                 </li>
                 <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-data-tab" data-toggle="pill" data-target="#pills-data" type="button" role="tab" aria-controls="pills-data" aria-selected="false">Database</button>
                 </li>
                 <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-trand-tab" data-toggle="pill" data-target="#pills-trand" type="button" role="tab" aria-controls="pills-trand" aria-selected="false">Trending Technology</button>
                 </li>
               </ul>
               <div class="tab-content" id="pills-tabContent">
               <div class="tab-pane fade show active" id="pills-front" role="tabpanel" aria-labelledby="pills-front-tab">
                <div className='row'>
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg1} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg2} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg8} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg4} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg5} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg9} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
               </div>

               <div class="tab-pane fade" id="pills-back" role="tabpanel" aria-labelledby="pills-back-tab">
               <div className='row'>
               <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg3} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg12} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg15} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg6} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg7} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg10} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
               </div>
               </div>
               <div class="tab-pane fade" id="pills-mob" role="tabpanel" aria-labelledby="pills-mob-tab">
               <div className='row'>
               <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg13} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg16} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg17} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg18} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg19} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg20} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                   
               </div>
               </div>
               <div class="tab-pane fade" id="pills-data" role="tabpanel" aria-labelledby="pills-data-tab">
                <div className='row'>
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg21} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg22} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg23} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg24} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg25} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg26} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
               </div>
               <div class="tab-pane fade" id="pills-trand" role="tabpanel" aria-labelledby="pills-trand-tab">
               <div className='row'>
               <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg27} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg28} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg29} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg31} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item p-0">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg32} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg33} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
               </div>
               </div>
               </div>
            </div>
        </div>
    )
}
export default Technology