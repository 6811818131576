import React from 'react';

const TopHeaderStyleThree = () => {

    return (
        <div className="topbar-area hidden-xs style3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <ul className="topbar-contact">
                            <li className="hidden-sm">
                                <i className="fa fa-envelope-open"></i>
                                <a href="mailto:info@metaqualt.com">info@metaqualt.com</a>
                            </li>
                            <li>
                                <i className="fa fa-phone"></i>
                                <a href="tel:++1(990)999–5554">+1 (990) 999–5554</a>
                            </li>
                            <li className="hidden-md">
                                <i className="fa fa-map-marker"></i>
                                05 kandi BR. New York
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 text-right">
                        <div className="toolbar-sl-share">
                            <ul>
                               
                                <li>
                                    <a href="#"><i className="fa fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fa fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fa fa-pinterest"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fa fa-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeaderStyleThree;