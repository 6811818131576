import React  from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

import contactHereImg from '../../assets/img/about/contact-here.png';

const CallUs = () => {

    return (
        <div className="rs-call-us relative pt-120 mb-50">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-5 hidden-md">
                        <div className="image-part">
                            <img src={contactHereImg} alt="" />
                        </div>
                    </div> */}
                    <div className="col-lg-12 pb-90 md-pb-80">
                        <div className="rs-contact-box text-center">
                            {/* <div className="address-item mb-26">
                                <div className="address-icon">
                                    <i className="fa fa-phone"></i>
                                </div>
                            </div> */}
                            <SectionTitle
                                sectionClass="sec-title3 text-center"
                                subtitleClass="sub-text style-bg"
                                subtitle="CALL US 24/7"
                                titleClass="title"
                                title="Let your business thrive with a reputable work party in the Web Development Sector"
                                descClass="desc"
                                description="You are just a few moments away from connecting with the top IT Solution Specialist - The one who will turn your ideas into reality with his tech expertise."
                            />
                            <div className="btn-part mt-40">
                                <Link className="readon learn-more" to="/contact">Open Up Here! </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* counter area start */}
            <CounterHomeFive />
                {/* counter area end */}
        </div>
    );
}

export default CallUs;