import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProjectTwo from '../../components/phpDevelopment/SingleProjectTwo';


import projectImg7 from '../../assets/img/project/7.jpg';
import projectImg8 from '../../assets/img/project/8.jpg';
import projectImg9 from '../../assets/img/project/9.jpg';
import projectImg10 from '../../assets/img/project/10.jpg';

import projectImg17 from '../../assets/img/bg/case-study.jpg';


const Technologies = () => {

    const options = {
        items: 4,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: true,
        stagePadding: 0,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                items: 1,
                nav: true,
            },
            768: {
                items: 2,
                nav: true,
            },
            1200: {
                items: 3,
                nav: true,
            },
            1366: {
                items: 3,
                nav: true,
            },
            1600: {
                items: 4,
                nav: true,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-case-study pt-0 pb-0">
                <div className="">
                    <div className="row margin-0 align-items-center">
                        <div className="col-lg-4 padding-0 md-mb-50">
                            <div className="case-study" style={{ backgroundImage: `url(${projectImg17})` }}>
                                <SectionTitle
                                    sectionClass="sec-title2 mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Our Expertise"
                                    titleClass="title testi-title white-color pb-20"
                                    title="Front-end Development Services"
                                    descClass="desc-big"
                                    description="Any Frontend Development Company can tack on a few lines of code. We go much further than this. By developing design pieces into tangible pieces, we strive to provide people with experiences that inspire them."
                                />
                            </div>
                        </div>
                        <div className="col-lg-8 padding-0">
                            <div className="case-study-wrap">
                                <div className="rs-project style3 modify1 mod md-pt-0">
                                    <OwlCarousel options={options} >
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg8} 
                                            Title="AngularJS Development"
                                            Text="The TypeScript-based Angular framework is one of the most popular choices for component-based frontend"
                                            projectURL="AngularJS"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg9} 
                                            Title="JavaScript Development"
                                            Text="JavaScript development services help you build a dynamic, responsive & feature-rich app for your organization." 
                                            projectURL="JavaScript"
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg10} 
                                            Title="ReactJS Development" 
                                            Text="The UI-centric library of ReactJS allows us to create innovative, intuitive, dynamic applications for our clients." 
                                            projectURL="ReactJS" 
                                        />
                                        <SingleProjectTwo 
                                            itemClass="project-item"
                                            projectImage={projectImg7} 
                                            Title="VueJS Development"
                                            Text="Develop future-focused apps with VueJs' lightweight, versatile, feature-rich, scalable platform." 
                                            projectURL="VueJS"
                                        />
                                       
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </React.Fragment>
	)
}

export default Technologies;