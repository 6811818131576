import React, { Component } from 'react';
import PostContent from './PostContent';
import BlockQuote from './BlockQuote';
import Commenting from './Commenting';
import SearchWidget from '../Widget/SearchWidget';
import RecentPostWidget from '../Widget/RecentPostWidget';
import CategoriesWidget from '../Widget/CategoriesWidget';
import imgMiddle from '../../assets/img/blog/inner/6.jpg';




const BlogDetailsMain = () => {

    return (
        <div className="rs-inner-blog pt-120 pb-120 md-pt-90 md-pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 order-last">
                        <div className="widget-area">
                            <SearchWidget />
                            <RecentPostWidget />
                            <CategoriesWidget />
                        </div>
                    </div>

                    <div className="col-lg-8 pr-34 md-pr-14">
                        <div className="blog-details">
                            <PostContent />
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogDetailsMain;