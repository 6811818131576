import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import Brand from "../../components/Common/Brand";
import bannerImg from "../../assets/img/banner/arti.png";
import SingleProcess from "../../components/Process/SingleProcess";
import Technologies from "./Technologies";
import SingleServiceFour from "../../components/Service/SingleServiceFour";
import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
// Working Process Images
import processImage1 from "../../assets/img/process/1.png";
import processImage13 from "../../assets/img/process/13.png";
import processImage14 from "../../assets/img/process/14.png";
import processImage15 from "../../assets/img/process/15.png";

// Service Icons
import imgMain40 from "../../assets/img/service/style2/main-img/ai11.png";
import imgMain41 from "../../assets/img/service/style2/main-img/ai12.png";
import imgMain42 from "../../assets/img/service/style2/main-img/ai13.png";
import imgMain43 from "../../assets/img/service/style2/main-img/ai15.png";
import imgMain28 from "../../assets/img/service/style2/main-img/ai14.png";
import imgMainai16 from "../../assets/img/service/style2/main-img/ai16.png";

// Service Image Icons
import experiencedai from "../../assets/img/service/style2/main-img/ai1.png";
import experiencedai1 from "../../assets/img/service/style2/main-img/ai2.png";
import experiencedai2 from "../../assets/img/service/style2/main-img/ai3.png";

// Service Icons
import img2 from "../../assets/img/technology/style3/2.png";
import img3 from "../../assets/img/technology/style3/3.png";
import img11 from "../../assets/img/technology/style3/11.png";
import img13 from "../../assets/img/technology/style3/13.png";
import img16 from "../../assets/img/technology/style3/16.png";
import img15 from "../../assets/img/technology/style3/15.png";
import img14 from "../../assets/img/technology/style3/14.png";
import img17 from "../../assets/img/technology/style3/17.png";

import techImg34 from "../../assets/img/technology/style2/34.svg";
import techImg35 from "../../assets/img/technology/style2/35.svg";
import techImg36 from "../../assets/img/technology/style2/36.svg";
import techImg37 from "../../assets/img/technology/style2/37.svg";
import techImg38 from "../../assets/img/technology/style2/38.svg";
import techImg9 from "../../assets/img/technology/style2/9.svg";
import techImg8 from "../../assets/img/technology/style2/8.svg";
import techImg4 from "../../assets/img/technology/style2/4.svg";
import techImg3 from "../../assets/img/technology/style2/3.svg";

import effectImg2 from "../../assets/img/about/dotted-3.png";
import effectImg3 from "../../assets/img/about/shape3.png";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import { CounterSection } from "../../components/CounterSection/Data";

import { Feature_Item } from "../../components/CounterSection/Data";
import CounterSectioin from "../../components/CounterSection/CounterSectioin";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

const artificial_intelligence_sec = () => {
  let data = CounterSection.artificialintelligence[0];
  const featureData = Feature_Item.artificialintelligence;
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-6 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="AI Innovation Hub"
                titleClass="title pb-25"
                title="Top-Tier AI Development Company
                                "
                descClass="desc pb-5"
                description="Elevate your AI journey with Metaqualt’s specialized development services, uniquely crafted to meet your project’s specific needs. From custom AI strategies for business intelligence and advanced analytics to Natural Language Processing (NLP) and scalable AI model development, Metaqualt is your trusted partner for unparalleled AI expertise and talent."
                secondDescClass="desc pb-16"
              />
              <ConsultNowBtn />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
          {/* counter area start */}
          {/* <CounterHomeFive /> */}
          {/* counter area end */}
        </div>
      </div>
      {/* <!-- banner section end --> */}

      {/* rs-services-area-start */}
      {/* <div className="counter_sec pt-80 pb-20">
        <div className="container">
          <div class className="row">
            <div className="col-md-12">
            <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Experienced Professionals"
            titleClass="title pb-10"
            title="Why Choose Metaqualt for Artificial Intelligence?"

          />
            </div>
          </div>
          <div className="counter_inner text-center" >
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <div className="counter_inner_sec">
                
                <div className="listar-feature_listar-feature_sec2">
                <div className="listar-feature_sec2">
                  <div className="listar-feature-icon-inner">
                    <h2>20+</h2>
                  </div>
                </div>
                <div className="counter_sec_des">
                  <h5>Experts in  Machine Learning</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="counter_inner_sec">
               
                <div className="listar-feature_listar-feature_sec2">
                <div className="listar-feature_sec2">
                  <div className="listar-feature-icon-inner">
                    <h2>20+</h2>
                  </div>
                
                <div className="counter_sec_des">
                  <h5>Over Successful Projects</h5>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
            <div className="counter_inner_sec ">
               
               <div className="listar-feature_listar-feature_sec2">
               <div className="listar-feature_sec2">
                 <div className="listar-feature-icon-inner">
                   <h2>20+</h2>
                 </div>
               
               <div className="counter_sec_des">
                 <h5>Over Successful Projects</h5>
                 </div>
               </div>
             </div>
             </div>
            </div>
            <div className="col-xl-3 col-lg-6">
            <div className="counter_inner_sec">
               
               <div className="listar-feature_listar-feature_sec2">
               <div className="listar-feature_sec2">
                 <div className="listar-feature-icon-inner">
                   <h2>20+</h2>
                 </div>
               
               <div className="counter_sec_des">
                 <h5>Over Successful Projects</h5>
                 </div>
               </div>
             </div>
             </div>
            </div>
          </div>
          </div>
         
        </div>

      </div> */}
      <CounterSectioin data={data} featureItemsData={featureData} />
      {/* rs-services-area-start */}

      {/* rs-servicess-area-start */}
      <div className="rs-process style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Guiding Innovation"
            titleClass="title pb-10"
            title="Metaqualt AI Development Services: Guiding Your Transformation"
            descClass="desc"
            description="Metaqualt’s team of AI experts supports businesses through every stage of their AI transformation. Our comprehensive AI solutions empower organizations to explore innovative AI possibilities and build robust models aligned with their unique goals.
                        "
            effectClass="heading-line"
          />

          <div className="rs-services style3 modify2 pt-20">
            <div className="row">
              <div className="col-lg-6  mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain40}
                  HoverImg={imgMain40}
                  Title="Tailored AI Application Development"
                  Text="From virtual assistants and customer service chatbots to fraud detection, our AI application development services are designed to meet project-specific requirements, ensuring seamless integration and optimal impact. 
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain41}
                  HoverImg={imgMain41}
                  Title="Metaqualt: Your AI Development Partner"
                  Text="At Metaqualt, we don’t just create AI solutions; we pioneer groundbreaking AI possibilities that align with your goals. Our reputation as a leading AI company is built on excellence, customization, and dedicated support.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain42}
                  HoverImg={imgMain42}
                  Title="Data Engineering Solutions for AI"
                  Text="Our data engineering approach spans data collection, cleaning, ETL processes, and big data handling to establish solid data models. This foundation is key to successful AI deployment in today’s data-centric environment.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain28}
                  HoverImg={imgMain28}
                  Title="Predictive Analytics Solutions"
                  Text="Using advanced statistical models and machine learning algorithms, Metaqualt’s predictive analytics solutions empower businesses to forecast outcomes, seize opportunities, and optimize strategies. From time series and demand forecasting to tailored needs, we cover it all."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain43}
                  HoverImg={imgMain43}
                  Title="Advanced Natural Language Processing (NLP)"
                  Text="Metaqualt leverages cutting-edge NLP techniques to deliver text analysis, multilingual support, chatbot solutions, and conversational AI, enhancing user experiences, streamlining operations, and automating routine tasks with efficiency and precision.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMainai16}
                  HoverImg={imgMainai16}
                  Title="Seamless AI Integration Services"
                  Text="Our AI integration services are secure and tailored, from API integrations to cloud enablement. Metaqualt’s integration solutions help businesses enhance existing systems, boost productivity, and maximize AI potential.
                                    "
                  btnClass="d-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
      <Technologies />
      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
      <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Process"
            titleClass="title title"
            title="Looking for the Best AI Solutions? Our Experts Are Ready to Take Your Business to the Next Level.
                            "
            effectClass="heading-line"
            descClass="desc w-80"
            description="Our experts master the development of Artificial Intelligence solutions that show more innovation and efficiency. In case you aspire to inject AI into your business processes, you've come to the right place. We offer advanced AI services to empower your business and make your operations better."
          />
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai}
                Title="Data-Informed Insights"
                Text="From Metaqualt, we leverage AI to make raw data actionable insights. Our AI solutions involve integrating machine learning and predictive analytics in order to enable you in making well-informed data-driven decisions that result in maximum business efficiency and growth."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai1}
                Title="Customized AI Solutions"
                Text="We closely co-operate with you and comprehend your business needs so that we can provide you with tailored AI solutions. Be it automating processes, improving customer experiences, or optimizing operations, our team designs an AI system that fits perfectly in line with your goals and challenges."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai2}
                Title="Future-Ready AI"
                Text="Metaqualt designs AI solutions that scale with business. Combining the newest technology and strategic foresight, it ensures that the corresponding growth of your business is matched in terms of the evolution of your AI systems so that you stay top of the game with innovative solutions for tomorrow."
              />
            </div>
          </div>
        </div>
      </div>
      {/* rs-services-area-start */}

      {/* rs-servicess-area-start */}
      {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
      {/* rs-services-area-start */}

      <div className="accordions pb-50">
        <div className="container">
          <FAQHeader data={faqDescriptions["ai-development"]} />

          <AccordionCards data={accordions.artificial_intelligence} />
        </div>
      </div>

      {/* newsletter-area-start */}
      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
        ctaTitleClass="epx-title"
        ctaTitle="Hire Our AI Experts and Revolutionize Your Business"
        ctaTextClass="exp-text"
        ctaText="Transform your ideas into intelligent solutions with artificial intelligence. From smart automation and predictive analytics-innovative scalable models towards improving your business processes-our AI experts create."
        ctaText2="Experience growth, efficiency, and success: with our personalized AI solutions to your goals and endless possibilities."
        cta2TextClass="exp-text mannage"
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      />
      {/* newsletter-area-end */}

      {/* working-process-area-start */}
      {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
      {/* working-process-area-end */}

      {/* brand-area-start */}
      {/* <Brand /> */}
      {/* brand-area-end */}
    </React.Fragment>
  );
};

export default artificial_intelligence_sec;
