import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';

import serviceIcons1 from '../../assets/img/service/style1/s1.png';
import serviceIcons2 from '../../assets/img/service/style1/s2.png';
import serviceIcons3 from '../../assets/img/service/style1/s3.png';
import serviceIcons4 from '../../assets/img/service/style1/s4.png';
const Service = () => {

    return (
        <div id="rs-service" className="rs-services main-home gray-bg style6 pt-50 pb-40 md-pt-80 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text style-bg"
                    subtitle="Empower Vision"
                    titleClass="title"
                    title="Bring Your Vision to Life"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 mb-20">
                        <SingleService
                            itemClass="services-item shape1"
                            serviceImage={serviceIcons1}
                            Title="Creative Ideas"
                            Text="Get eye-pleasing software that is designed to impress with our years of customer-centric ideation and strategic planning. We brainstorm ideas and come up with mind-blowing tech solutions for your enterprise."
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 mb-20">
                        <SingleService
                            itemClass="services-item shape1 active"
                            serviceImage={serviceIcons2}
                             Title="Innovative Tools"
                            Text="How about fulfilling your web development needs with someone who understands the latest trends? We put to use our experience in an assortment of technologies to innovate the new product. "
                       />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6  mb-20">
                        <SingleService
                            itemClass="services-item shape1"
                            serviceImage={serviceIcons3}
                            Title="Better Performance"
                            Text="The award-winning product we deliver you is the fruit of our team’s love for software development.  We ensure it performs like a supercharged engine using the most powerful frameworks, and tools. "
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6  mb-20">
                        <SingleService
                            itemClass="services-item shape1"
                            serviceImage={serviceIcons4} 
                            Title="Goal Achievement"
                            Text="You get an unputdownable product for web and mobile made to meet your business goals. Being customer-centric is our goal and it gets accomplished when your goal is met."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;