import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg14 from '../../assets/img/service/26.png';
import serviceImg13 from '../../assets/img/service/25.png';
import serviceImg11 from '../../assets/img/service/27.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text gold-color"
                    subtitle="Hire Best Resources"
                    titleClass="title new-title"
                    title="Our Hiring Models"
                    descClass="desc"
                    description="Hire as per the unique needs of your development project and provide you different hiring models to accommodate your needs better and get complete control over your development budget and time."
                    effectClass="heading-line"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-blue"
                            serviceImg={serviceImg14}
                            Title="Full Time Hiring"
                            Text="9 Hours Per Day & Minimum 30 days"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <SingleServiceSix
                            itemClass="services-item bg-light-green"
                            serviceImg={serviceImg13}
                            Title="Part Time Hiring"
                            Text="4 Hours Per Day & Minimum 30 days"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SingleServiceSix 
                            itemClass="services-item bg-light-red"
                            serviceImg={serviceImg11}
                            Title="Hourly Time Hiring" 
                            Text="Flexible hrs Per Day & Minimum 50 hrs"
                        />
                    </div>
                    
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

