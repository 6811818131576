import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/banner-10.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain16 from '../../assets/img/service/style2/main-img/16.png';
import imgHover16 from '../../assets/img/service/style2/hover-img/16.png';

import imgMain17 from '../../assets/img/service/style2/main-img/17.png';
import imgHover17 from '../../assets/img/service/style2/hover-img/17.png';

import imgMain18 from '../../assets/img/service/style2/main-img/18.png';
import imgHover18 from '../../assets/img/service/style2/hover-img/18.png';

import imgMain19 from '../../assets/img/service/style2/main-img/19.png';
import imgHover19 from '../../assets/img/service/style2/hover-img/19.png';

import imgMain20 from '../../assets/img/service/style2/main-img/20.png';
import imgHover20 from '../../assets/img/service/style2/hover-img/20.png';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon1 from '../../assets/img/service/style2/main-img/1.png';
import serviceIcon15 from '../../assets/img/service/style2/main-img/15.png';
import serviceIcon3 from '../../assets/img/service/style2/main-img/3.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const AngularJsDevelopment = () => {
    return (
        <React.Fragment>

             {/* <!-- banner section start --> */}
             <div className="rs-about pt-150 pb-50 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="We are Different"
                                titleClass="title pb-25"
                                title="We Assist Businesses in Developing MVC-Ready Applications Using AngularJS."
                                descClass="desc pb-5"
                                description="Outsmart your competitors using intuitive and dynamic single-page web apps using Angular development services. The most effective way to simplify app development is to opt for an Angular development service. With us you let your brand’s dream convert into a tangible technical solution with a declarative UI and modular structure."
                                secondDescClass="desc pb-16"
                                secondDescription="“Sheer robust front-ends with Angular development” is something we boast about. To make it happen, we leverage the latest tools and strive to generate the trendiest and most dynamic outcomes."
                            />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}


            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-50 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Expertise"
                        titleClass="title pb-10"
                        title="The AngularJs Development Services We Offer"
                        descClass="desc"
                        description="All it takes for you to stay on top is a customer-centric and top-notch AngularJS service. We at MetaQualt develop concrete, scalable, flexible, and real-time apps."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-20">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain16}
                                    HoverImg={imgHover16}
                                    Title="Custom AngularJS Development"
                                    Text="We only sell what sells. With our profound experience in providing a performance-driven, scalable, and modern app made using AngularJS, you can be sure of getting an extra edge."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain17}
                                    HoverImg={imgHover17}
                                    Title="Dynamic Web App Development"
                                    Text="Our AngularJS development crew has a razor-sharp focus on delivering dynamic apps with rich user experience for desktops and mobiles. Hire our AngularJS Developers for creating a website that visitors love to interact with.
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain18}
                                    HoverImg={imgHover18}
                                    Title="Angular Upgrade Services"
                                    Text="Want to speed up your app development process? Stop using that old version and contact our Angular JS services to upgrade to the latest version of AngularJS. High-speed app development means faster conversions"
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain19}
                                    HoverImg={imgHover19}
                                    Title="Application Migration Service"
                                    Text="Keep your Angular JS apps updated with modern features with our felicitous migration services. Our team leverages modern tools to give your end-users an immersive experience."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain20}
                                    HoverImg={imgHover20}
                                    Title="AngularJS App Support & Maintenance"
                                    Text="Your app doesn’t just shine, it shines consistently with our support and maintenance team at your service. With our best support & maintenance, your app remains safe, up-to-date, and performs compellingly."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 d-flex align-items-center align-content-center">
                                <SectionTitle
                                    sectionClass="sec-title2 text-center mt-10 mb-10"
                                     titleClass="title pb-10 fz-36"
                                    title="Our Professional Experts Are Inclined Towards Developing Robust Front-end With AngularJSs"
                                    effectClass="heading-line"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            {/* <Technologies /> */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title"
                        title="Why Consider Our Development Services?"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                    />
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon14}
                            Title="Proven Expertise"
                            Text="Hand coders are at your service to ensure optimum and customized user experience. We are award-winning developers holding the record for handling numerous successful Angular JS projects. "
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Customer-Centric" 
                            Text="Fortune favors your brand as you choose our AngularJS squad that builds user-centric, sophisticated, and performance-based mobile and web apps. We begin keeping the end users in mind - our secret of success!" 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon15}
                            Title="Competitive Rate" 
                            Text="We offer AngularJS development services at a competitive price without compromising the quality of your product. Rest assured! You are in touch with the best deals and equally best outputs in the market. " 
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="Dedicated Team" 
                            Text="Putting our customer and their needs first, we always come up with highly-performing and well-structured codes. You get to meet a large pool of AngularJS coders who are always hungry to code & build unique solutions." 
                        />
                    </div>
                </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
             {/* rs-servicess-area-start */}
             {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 
            
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
				ctaTitleClass="epx-title"
				ctaTitle="Hire Dedicated AngularJS Talents"
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, deployment, design, and support to turn them into irresistible apps. With us, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default AngularJsDevelopment;