
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-owl-carousel2/src/owl.carousel.css';
import './assets/scss/App.scss';
ReactDOM.render(
  	<React.StrictMode> 
		<BrowserRouter basename="/metaqualt.com">
        	<App />
		</BrowserRouter>
  	</React.StrictMode>,
  	document.getElementById('root')
);

reportWebVitals();
 