import React from "react";

import postImage from "../../assets/img/blog/inner/5.jpg";

import { data } from "./data";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const PostContent = () => {
  const { blogId } = useParams();
  console.log(blogId);

  const post = data.find(
    (b) => b.id === Number(blogId)
  );

  return (
    <div className="post-content">
      <h5>{post.title}</h5>
      <p>{post.intro[0]}</p>
      <p>{post.intro[1]}</p>
      <div className="bs-img mb-34">
        <img src={postImage} alt="Post Image" />
      </div>
      <div className="blog-full">
        <ul className="single-post-meta">
          <li>
            <span className="p-date">
              <i className="fa fa-calendar"></i>
              December 11, 2024
            </span>
          </li>
          <li>
            <span className="p-date">
              <i className="fa fa-user"></i>
              Admin
            </span>
          </li>
        </ul>
        <h5>{post.sections[0].title}</h5>
        <h6>{post.sections[0].points[0].title}</h6>
        <p>{post.sections[0].points[0].description}</p>
        <h6>{post.sections[0].points[1].title}</h6>
        <ul class="dots">
          <li>{post.sections[0].points[1].description[0]}</li>
          <li>{post.sections[0].points[1].description[1]}</li>
        </ul>
        <h6>{post.sections[0].points[2].title}</h6>
        <ul class="dots">
          <li>{post.sections[0].points[2].description[0]}</li>
          <li>{post.sections[0].points[2].description[1]}</li>
        </ul>
        <h6>{post.sections[0].points[3].title}</h6>
        <ul class="dots">
          <li>{post.sections[0].points[3].description[0]}</li>
          <li>{post.sections[0].points[3].description[1]}</li>
        </ul>
        <h6>{post.sections[0].points[4].title}</h6>
        <p>{post.sections[0].points[4].description}</p>
        <h5>{post.sections[1].title}</h5>
        <p>{post.sections[1].subtitle}</p>
        <ul class="dots">
          <li>{post.sections[1].points[0]}</li>
          <li>{post.sections[1].points[1]}</li>
          <li>{post.sections[1].points[2]}</li>
          <li>{post.sections[1].points[3]}</li>
        </ul>
        <h6>{post.sections[2].title}</h6>
        <p>{post.sections[2].subtitle}</p>
        <h6>{post.sections[2].points[0].title}</h6>
        <p>{post.sections[2].points[0].description}</p>
        <h6>{post.sections[2].points[1].title}</h6>
        <p>{post.sections[2].points[1].description[0]}</p>
        <p>{post.sections[2].points[1].description[1]}</p>
        <h6>{post.sections[2].points[2].title}</h6>
        <p>{post.sections[2].points[2].description[0]}</p>
        <p>{post.sections[2].points[2].description[1]}</p>
        <h6>{post.sections[2].points[3].title}</h6>
        <p>{post.sections[2].points[3].description}</p>
        <h5>{post.conclusion.title}</h5>
        <p>{post.conclusion.summary}</p>
      </div>
    </div>
  );
};

export default PostContent;
