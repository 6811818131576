import React from "react";
import { Link } from "react-router-dom";

const SingleService = (props) => {
	return(
        <div className={props.itemClass}>
            <div className="services-icon">
                <div className="image-part">
                    <img
                        src={props.serviceImage}
                        alt={props.Title}
                    />
                </div>
            </div>
            <div className={props.shapeCall}>{props.shapeImg}</div>
            <div className="services-content">
                <div className="services-text">
                    <h3 className="services-title">

                            {props.Title}

                    </h3>
                </div>
                <div className="services-desc">
                    <p>
                        {props.Text}
                    </p>
                </div>
            </div>
        </div>
	)
}

// const SingleService = ({ itemClass, serviceImage, Title, Text }) => {
//   return (
//      <div className="col-lg-4 col-md-6 mb-30">
//        <div className={itemClass}>
//       <div className="services-icon">
//         <div className="image-part">
//           <img src={serviceImage} alt={Title} />
//         </div>
//       </div>
//       {/* <div className={props.shapeCall}>{props.shapeImg}</div>  */}
//       <div className="services-content">
//         <div className="services-text">
//           <h3 className="services-title">{Title}</h3>
//         </div>
//         <div className="services-desc">
//           <p>{Text}</p>
//         </div>
//       </div>
//     </div>
//      </div>
//   );
// };

export default SingleService;
