export const accordions = {
  digitl_market: [
    {
      id: 1,
      question: "Why invest in digital marketing services?",
      answer: {
        text: "Digital marketing boosts your brand's visibility, engagement, and sales with targeted online strategies.",
      },
    },
    {
      id: 2,
      question: "How does digital marketing help my business grow?",
      answer: {
        text: "It ensures a better brand awareness, drives traffic, and converts leads into loyal customers.",
      },
    },
    {
      id: 3,
      question: "How do I pick the right digital marketing agency?",
      answer: {
        text: "Find services that include, for example, SEO, PPC, Social Media, and Content Marketing. This should be accompanied by creativity, proven results, industry recognition, and excellent testimonials from satisfied clients.",
      },
    },
  ],
  quality_engineering: [
    {
      id: 1,
      question: "What kinds of QA testing services does Metaqualt provide?",
      answer: {
        text: "Metaqualt provides extensive diversified testing services to make sure the software is absolutely quality of the highest level for your products. Here is our list of principal QA services:",
        bulletPoints: [
          "Test automation",
          "Functional testing",
          "Nonfunctional testing",
        ],
      },
    },
    {
      id: 2,
      question:
        "Why to decide on Metaqualt for software testing and QA services?",
      answer: {
        text: "Our QA and software testing services will provide great value to your brand and make your product a more reliable product. Some key advantages of selecting Metaqualt for your software quality assurance needs are as follows:",
        bulletPoints: [
          "Error-free and bug-free launches",
          "Enhanced user experience",
          "Improved product build quality",
          "Increased efficiency and productivity",
          "Increase sales conversion",
        ],
      },
    },
    {
      id: 3,
      question:
        "How does Metaqualt differ from others in its software testing and QA services?",
      answer: {
        text: "Metaqualt realizes every project is different. We hire the best QA engineers with relevant experience and forward them for every project in a strategic development and testing structure. In doing so, we ensure via both manual and automated testing that we don't leave any stone unturned for the identification of product issues that need settling for optimal performance and overall quality user experience.",
        bulletPoints: [],
      },
    },
    {
      id: 4,
      question:
        "What measures do you take into account during software testing?",
      answer: {
        text: "We have the key software testing metrics of response time, endurance, crash ratio, bandwidth, error rate, security, and resource utilization in delivering a robust, high-quality product.",
        bulletPoints: [],
      },
    },
    {
      id: 5,
      question: "How many testing rounds are enough for my project?",
      answer: {
        text: "There is no strict answer to 'when to stop testing,' because testing is an iterative process; once we have run several cycles, we know we can be confident that your software has been adequately tested against all functional requirements.",
        bulletPoints: [],
      },
    },
    {
      id: 6,
      question: "Do you provide the results of the testing in reports?",
      answer: {
        text: "Yes. We prepare comprehensive reports including audits summary and strategy outlines. These reports inform testing approaches that have been adopted thus making it easier for you to know the extent of work done by Metaqualt's QA professionals.",
        bulletPoints: [],
      },
    },
    {
      id: 7,
      question: "What are the benefits of outsourcing your QA services?",
      answer: {
        text: "There are several benefits of outsourcing your QA services from Metaqualt that includes the following.",
        bulletPoints: [
          "Any time at your convenience",
          "Professional and experienced QA engineers",
          "Fewer days before getting the product to market",
          "Quality and safety are assured",
          "Up-to-date with the latest tools and methodologies",
        ],
      },
    },
    {
      id: 8,
      question:
        "Metaqualt - Does it guard client intellectual property confidentiality well?",
      answer: {
        text: "We take care to respect client confidentiality. From initial signing of an NDA to private repositories of secure code, we safeguard your intellectual property over the course of our engagement, including secure code transfer and clear ownership upon delivery.",
        bulletPoints: [],
      },
    },
  ],

  ux_ui_design: [
    {
      id: 1,
      question:
        "What design services do you provide under your UI/UX umbrella?",
      answer: {
        text: "We have a range of UI/UX services up our sleeve, covering user research, wireframing, prototyping, interface designing, and usability testing in creating engaging digital experiences.",
      },
    },
    {
      id: 2,
      question: "How much does it cost to hire designers for UI/UX?",
      answer: {
        text: "Our prices depend on the scope of the project and its requirements. Contact us for a quote tailored to your specific needs",
      },
    },
    {
      id: 3,
      question: "What is your design approach?",
      answer: {
        text: "Our approach is user-centric: starting from deep research and planning to iterative design, prototyping, and testing for optimal functionality and appeal.",
      },
    },
    {
      id: 4,
      question:
        "What value does Metaqualt bring in improving my website or app's user interface and experience?",
      answer: {
        text: "Our team uses research, user insights, and industry best practices, in order to create designs that go further on usability improvement, aesthetics elevation, and the development of user satisfaction.",
      },
    },
    {
      id: 5,
      question: "How do I start a design project with Metaqualt?",
      answer: {
        text: "Contact us via our website. We will guide you through the first consultation, get to know your requirements, and present further work.",
      },
    },
    {
      id: 6,
      question: "What if I don't like the design work?",
      answer: {
        text: "Your satisfaction is of utmost importance to us. We provide revisions upon revision for design work towards perfect coordination with your idea and feedback.",
      },
    },
    {
      id: 7,
      question: "What am I supposed to do?",
      answer: {
        text: "As our collaborator, you'll give us the initial project requirements, check on feedback in the review stages, and, if needed, any brand-specific assets for our design.",
      },
    },
    {
      id: 8,
      question:
        "Which tools and technologies are applied by Metaqualt for UI/UX design?",
      answer: {
        text: "We make use of a mix of market-leading tools: Sketch, Adobe XD, Figma, Photoshop, Illustrator, InVision Studio, Adobe After Effects, and Zeplin. Our complete toolkit enables cutting-edge and customized solutions.",
      },
    },
    {
      id: 9,
      question:
        "How long will it take to deliver a UI/UX design project for Metaqualt?",
      answer: {
        text: "It will depend on how complex and extensive the scope of the project is. Normally, UI/UX projects usually take a few weeks to up to several months in duration with clear milestones set forth during the period.",
      },
    },
    {
      id: 10,
      question: "Innovate Your Digital Experience?",
      answer: {
        text: "UI/UX design services help make experiences meaningful, memorable, and effective. Collaborate with Metaqualt to create a user-friendly, impactful digital product today!",
      },
    },
  ],
  artificial_intelligence: [
    {
      id: 1,
      question: "Experienced AI Professionals?",
      answer: {
        text: "Our specialized AI team has extensive experience handling complex AI projects and delivering powerful, impactful solutions.",
      },
    },
    {
      id: 2,
      question: "Innovation with Strong R&D Focus?",
      answer: {
        text: "Metaqualt invests heavily in R&D to develop cutting-edge algorithms and models, helping clients bring unique, competitive AI solutions to market.",
      },
    },
    {
      id: 3,
      question: "Prioritizing Security & Compliance?",
      answer: {
        text: "With security as our priority, we uphold strict confidentiality practices, sign NDAs, and implement data protection measures to maintain the highest standards throughout each project..",
      },
    },
  ],
  machine_learning: [
    {
      id: 1,
      question: "What is machine learning?",
      answer: {
        text: "Machine learning is a type of artificial intelligence that enables computers to learn from data and improve over time. It does not require explicit programming; instead, it uses algorithms that dig through patterns in data with the aim of predicting or making decisions.",
      },
    },
    {
      id: 2,
      question:
        "How does machine learning, deep learning, and artificial intelligence differ?",
      answer: {
        bulletPoints: [
          "Artificial Intelligence (AI): A broad field of activity focused on creating intelligent systems",
          "Machine Learning: This is an area of ML and applied when the deep neural network, especially in performing tasks such as image recognition and natural language processing, is used.",
        ],
      },
    },
    {
      id: 3,
      question: "How might Metaqualt help my business with machine learning?",
      answer: {
        text: "Metaqualt can also create tailored ML solutions depending on your needs. From predictive analytics to natural language processing and image recognition, our team can craft ML systems that help you and your organization capture value and innovate.",
      },
    },
    {
      id: 4,
      question: "What can be built with machine learning?",
      answer: {
        text: "Metaqualt can develop an ML-based solution in such domains as:",
        bulletPoints: [
          "E-commerce: Personalized recommendations",
          "Healthcare: Predictive diagnostics",
          "Finance: Fraud detection",
          "Retail & Media: Customer insights",
        ],
      },
    },
    {
      id: 5,
      question: "How do you ensure quality and accuracy of ML models?",
      answer: {
        text: "We use best practices: data preprocessing, feature engineering, and cross-validation in order to ensure high-quality and accurate models. Our testing and code review assure the best performance and security.",
      },
    },
    {
      id: 6,
      question: "How much does an ML solution cost?",
      answer: {
        text: "The price is mostly determined by the complexity of your project, the amount of available data and the expertise needed. Contact us for a custom quotation.",
      },
    },
    {
      id: 7,
      question:
        "Do you have an experience with the existing data infrastructure we work with?",
      answer: {
        text: "Absolutely! We can integrate ML models with your existing data systems. This can either be databases, cloud platforms, or other infrastructures.",
      },
    },
    {
      id: 8,
      question: "How do you protect sensitive data and enforce privacy?",
      answer: {
        text: "We encrypt your data both in transit and rest. In addition to this, we enforce strict controls on access and have regular security audits in place.",
      },
    },
  ],
  data_science: [
    {
      id: 1,
      question: "Why do I need to hire dedicated data scientists for my project?",
      answer: {
        text: "If you are hiring dedicated data scientists from Metaqualt in this fast-paced, tech-driven market, you reap a lot of benefits from it.",
        bulletPoints: [
          "Specialized Expertise: Tap experts with additional knowledge in particular areas, thus no extensive training is required.",
          "Real-Time Process Improvement: Monitor and refine business processes through data insights in real time.",
          "Flexible Scaling: Change according to the project requirements that may have to shift due to many reasons.",
          "Collaborative Communication: Sustain constant collaboration to keep in line with the aims of projects.",
        ],
      },
    },
    {
      id: 2,
      question: "How do you approach your data science solution?",
      answer: {
        text: "Metaqualt applies a well-tested methodology to ensure an effective data science solution.",
        bulletPoints: [
          "Problem Definition/Understanding of the Business Problem: Define the objectives.",
          "Data Gathering: Source data from clients or third parties",
          "EDA: Clean and preprocess data following good practices on data engineering, together with analyzing it",
          "ML Model Development: Develop, train, and test suitable machine learning models according to your needs",
          "Integration & Deployment: Integrate ML systems into existing software or IT infrastructure. MLOps shall be used for monitoring and maintenance.",
        ],
      },
    },
    {
      id: 3,
      question: "How secure is the enterprise data in outsourcing the data science services?",
      answer: {
        text: "Data security is on one of the top priorities at Metaqualt. We are following the guidelines of ethical duties and industry standards, conducting constant regular security audits along with transparent algorithms, and anonymizing as well as de-identifying the data to keep away that critical information from being accessed. We are strictly following privacy regulations and best practices in AI and data science. ",
      },
    },
    {
      id: 4,
      question: "What are some of the tools and technologies you use for data science projects?",
      answer: {
        text: "All our data scientists are updated about the latest tools and technologies. This includes:",
        bulletPoints: [
          "All our data scientists are updated about the latest tools and technologies. This includes:",
          "Programming & Databases: Python, SQL, MongoDB.",
          "ML Models: Linear regression, SVM, XGBoost, Clustering, Time Series Models (SARIMAX, LSTM).",
          "Neural Networks: CNN, DNN, RNN.",
          "Visualization Tools: Power BI, Plotly, Seaborn, Matplotlib.",
          "Cloud Platforms: AWS, Azure, Google Cloud Platform (GCP).",
        ],
      },
    },
    {
      id: 5,
      question: "Do you help to apply the integration of Data Science solutions with other systems?",
      answer: {
        text: "Yes, Metaqualt specializes in tailoring custom data science solutions to work within existing systems. We ensure an easy integration and fine-tune the setup for a smooth user experience.",
      },
    },
    {
      id: 6,
      question: "Which areas would benefit from data science?",
      answer: {
        text: "Though data science can be used in any industry, the following sectors would benefit the most:",
        bulletPoints: [
          "Healthcare: Improve patient care, optimize hospital operations, and advance medical research",
          "Finance: Improve fraud detection, risk management, and personalized financial services.",
          "Retail & E-commerce: Forecast demand, Personalize the customer experience, and Price to maximize",
          "Transportation & Logistics: Improve routes, predict maintenance, and increase overall efficiency",
          "Education: Personalized learning and better resource allocation",
        ],
      },
    },
    {
      id: 7,
      question:
        "How long does a typical implementation take?",
      answer: {
        text: "This depends upon the complexity of projects involved in size of dataset, analysis scope, and specific solution goals. Metaqualt ensures on-time delivery by tailoring each project to suit the client's specific needs.",
      },
    },
    {
      id: 8,
      question: "How do I sign up for Metaqualt's data science services?",
      answer: {
        text: "It is easy to get started:",
        bulletPoints: [
          "Fill out our Contact Us form.",
          "Email us your requirements and references.",
          "Schedule a consultation to talk about your project goals and objectives.",
        ],
      },
    },
  ],
  software_development: [
    {
      id: 1,
      question: "What is the difference between software and a software product?",
      answer: {
        text: "A software is a group of programs designed to perform predefined activities in an organization or system, whereas a software product is the proprietary solution developed to be marketed to end-users, typically containing features, updates, and maintenance. ",
      },
    },
    {
      id: 2,
      question: "How do I start outsourcing my software development project?",
      answer: {
        text: "Contact us first, with your requirements for the project, and we will then schedule a consultation over which we will discuss a comprehensive project proposal outlining the steps of our collaboration for smooth outsourcing.",
      },
    },
    {
      id: 3,
      question: "How much does it cost to develop a software product?",
      answer: {
        text: "The cost is determined by the complexity, features, and timeline; a simple web or mobile app will cost about $50,000, while complex enterprise or SaaS-type solutions can easily reach over $1 million. ",
      },
    },
    {
      id: 4,
      question: "How long does it take to develop a software product?",
      answer: {
        text: "The development timeline can vary from a few months to over a year, depending on the complexity of the project, the feature set, and the experience of the development team",
       
      },
    },
    {
      id: 5,
      question: "How does one identify the best software development company?",
      answer: {
        text: "Look for a company that has a proven track record, a good portfolio, industry expertise, and good client reviews. Their approach to technology and project management should also be understood.",
      },
    },
    {
      id: 6,
      question: "Why India for offshore software development services?",
      answer: {
        text: "India offers a large pool of experienced IT professionals, cost-effective services, high standards of quality, and innovations to become an ideal offshoring destination for any software development project",
    
      },
    },
    {
      id: 7,
      question:
        "What type of support and maintenance after software deployment?",
      answer: {
        text: "Metaqualt offers on-going support and maintenance, including updates, bug fixes, performance monitoring, and technical assistance so that your software is secure, functional, and up-to-date.",
      },
    },
    {
      id: 8,
      question: "Ensuring the safety of data while developing the software How do you ensure data security while developing software?",
      answer: {
        text: "Metaqualt follows secure coding practices, data encryption, access controls, and regular security audits to ensure the safety of your data. We strictly follow all the compliance standards like HIPAA, GDPR, and PCI-DSS so that your data is protected throughout the development lifecycle.",
      },
    },
    {
      id: 9,
      question: " Can the new software integrate with our existing systems?",
      answer: {
        text: "Yes, seamless integration of the new software with the existing one is our specialty. Compatibility, and at the same time, improving functionality so that it may help to streamline workflows and be well-integrated into a system- this does everything it promises.",
      },
    },
    {
      id: 10,
      question: " How do you ensure quality in your software development projects?",
      answer: {
        text: "Metaqualt ensures high-quality software through rigorous code review, automated testing, continuous integration, and comprehensive quality assurance practices. We test every stage so that the software is reliable as well as robust in its performance.",
      },
    },
    {
      id: 11,
      question: "Why do I need to hire a custom software development company?",
      answer: {
        text: "Designing a custom software development company like Metaqualt assures you that you get bespoke solutions, which are tailored to your particular business. Thus, you will get more flexibility, scalability, and integration than you would in off-the-shelf options.",
      },
    },
  ],
  CRM_development: [
    {
      id: 1,
      question: "What is the difference between CRM and a CRM software solution?",
      answer: {
        text: "CRM represents the overall strategy for managing customer relationships, while a CRM software would be used for carrying out such a process with the aid of automation tools for tracking interactions, management of the sales pipeline, and customer data analysis",
      },
    },
    {
      id: 2,
      question: "How do I start implementing CRM in my business?",
      answer: {
        text: "Initiate: Get in touch with Metaqualt to talk over your business requirements. We shall organize a meeting solely aimed at knowing what you need and how we can fit a tailored CRM solution for you.",
      },
    },
    {
      id: 3,
      question: "How much will a CRM system cost to develop?",
      answer: {
        text: "Developing a CRM system will cost according to features, complexity, and the selected platform. For instance, a basic CRM can run between $20 000, while complex and highly customized ones can range to around $50,000 or up to more than $150,000.",
      },
    },
    {
      id: 4,
      question: "How long does it take to have a CRM system?",
      answer: {
        text: "It depends on the complexity and scope of the CRM system. Some simple system could be developed in a couple of weeks, while advanced, custom CRM solutions may require several months to develop.",
       
      },
    },
    {
      id: 5,
      question: "How do I choose the best CRM for my business?",
      answer: {
        text: "The CRM for your business is going to depend on those needs, size, and objectives. We can help you evaluate Salesforce, Odoo, Zoho, or a custom-made solution to determine what's best suited for your business.",
      },
    },
    {
      id: 6,
      question: "Why should I invest in a custom CRM solution?",
      answer: {
        text: "Customized CRM solutions are tailor-made for your business. They give more flexibility, scalability, and integration compared to generic off-the-shelf software. An individualized CRM gives you that competitive edge due to unique needs and goals.",
    
      },
    },
  ],
  Web_Development: [
    {
      id: 1,
      question: "Why is business-specific web app development important?",
      answer: {
        text: "In the digital age, a custom web app helps your business stand out. Key benefits include:",
        bulletPoints: [
          "Establishing credibility for your brand.",
          "Expanding your reach to a larger audience.",
          "Enhancing promotions through PPC campaigns.",
          "Monitoring business performance with analytics tools.",
          "Enhancing customer experience and satisfaction through data analytics.",
        ],
      },
    },
    {
      id: 2,
      question: "What are the advantages of outsourcing web application development in India?",
      answer: {
        text: "There are multiple advantages of outsourcing to India:",
        bulletPoints: [
          "You get the flexibility to work as per your time zone.",
          "Get hold of skilled and experienced developers.",
          "Fastened up development timelines.",
          "High-quality services at low costs.",
          "Up-to-date cutting-edge technologies.",
          "Familiarity with varied development platforms.",
        ],
      },
    },
    {
      id: 3,
      question: "Why Choose Metaqualt for Web Application Development Services?",
      answer: {
        text: "Metaqualt is a name known for delivering reliable and customized solutions. Here's why clients prefer to hire us:",
        bulletPoints: [
          "Extensive industry experience.",
          "Clear and transparent processes.",
          "Timely project deliveries.",
          "Solutions customized to your unique needs.",
          "Direct communication with a dedicated team.",
          "Enhanced user experiences guaranteed.",
          "Enhanced user experiences guaranteed.",
        ],
      },
    },
    {
      id: 4,
      question: "What factors influence the cost of custom web development?",
      answer: {
        text: "The cost varies depending on factors such as the development platform, project complexity, desired features, and developer location. Share your requirements with us to receive a detailed estimate.",
       
      },
    },
    {
      id: 5,
      question: "How long does it take to build a website or web app?",
      answer: {
        text: "Development timelines depend on the project's complexity, design, features, and testing requirements. Contact us with your project details for a precise timeline.",
      },
    },
    {
      id: 6,
      question: "Can you work with our pre-designed UI/UX?",
      answer: {
        text: "Absolutely! Whether you have designs ready or need assistance from scratch, our team can bring your vision to life at any development stage.",
      },
    },
    {
      id: 7,
      question: "Are your developers available for meetings in my time zone?",
      answer: {
        text: "Our working hours are 10 AM to 7 PM IST (Mon-Fri), but we can adjust meeting schedules by +/- 3 hours to accommodate your time zone.",
      },
    },
    {
      id: 8,
      question: "How do you ensure that my project is kept confidential?",
      answer: {
        text: "We ensure the safety of your intellectual property with NDAs, secure code management, and full ownership transfer, so your project will be protected at every turn.",
      },
    },
    {
      id: 9,
      question: "Do you offer any post-development support?",
      answer: {
        text: "We do. We offer a flexible bucket model, which gives you the option to hire developers on demand to keep your web app in top condition and up-to-date.",
      },
    },
    {
      id: 10,
      question: "Can Metaqualt help complete my incomplete web/app development project?",
      answer: {
        text: "WYes, we specialize in helping businesses finish projects left incomplete by other teams. Our experts will review your requirements and deliver a robust solution to meet your needs.",
      },
    },
  ],
  MobileAppDevelopment: [
    {
      id: 1,
      question: "What mobile app development technologies do you use?",
      answer: {
        text: "We make use of a comprehensive set of tools and technologies to deliver cutting-edge mobile apps:",
        bulletPoints: [
          "Programming Languages: Kotlin, Java, Swift, Flutter, React Native, Ionic",
          "Tools & Utilities: Android Studio, Xcode, Visual Studio Code, WebStorm, IntelliJ, SwiftUI, Firebase",
          "Databases: SQLite, Core Data, Firestore, Realm, Hive, ObjectBox",
        ],
      },
    },
    {
      id: 2,
      question: "How much does the development of a custom mobile application cost?",
      answer: {
        text: "The expense for developing a mobile app varies based on:",
        bulletPoints: [
          "platform - iOS, Android or both",
          "complexity level of the application",
          "scope of technologies and tools in use",
          "High-quality services at low costs.",
          "location and the developer's experience",
          "Ask us for a quote using your specifications.",
        ],
      },
    },
    {
      id: 3,
      question: "How many weeks does it take to develop a custom mobile app?",
      answer: {
        text: "The time frame involved in developing a mobile app is determined by:",
        bulletPoints: [
          "Technology Stack and Platform",
          "Number of designs",
          "Features and functionality",
          "Testing and debugging requirements",
          "Contact us for a customized estimate of the time required for your project.",
        ],
      },
    },
    {
      id: 4,
      question: "Can you help me finish my incomplete app development project?",
      answer: {
        text: "Yes! Most of our clients come to us to save their partially completed projects. Our professionals analyze the work done and deliver solutions to finish the app in the most efficient and effective manner.",
       
      },
    },
    {
      id: 5,
      question: "We already have designs. Can you work with those?",
      answer: {
        text: "Yes, we can! Whether you have pre-made UI/UX designs or need us to design them, we are adept at delivering quality applications at any stage of the development cycle.",
      },
    },
    {
      id: 6,
      question: "I have a website. Can you create a mobile app for it?",
      answer: {
        text: "Of course! With our mobile-first approach, we can transform your website into an engaging mobile application that reaches your audience more effectively.",
      },
    },
    {
      id: 7,
      question: "Will your developers be able to meet in my time zone??",
      answer: {
        text: "Our developers are flexible and work across multiple time zones, so it is quite easy to collaborate. We work from 10 AM to 7 PM IST (Mon–Fri) but can accommodate meetings up to +/- 3 hours from our regular hours.",
      },
    },
    {
      id: 8,
      question: "What is your policy on intellectual property and app ownership?",
      answer: {
        text: "We protect your intellectual property with fool proof agreements. From the time we sign NDAs to completely giving you complete code ownership, you can be ensured of full ownership over every stage of the application development process.",
      },
    },
    {
      id: 9,
      question: "Does post-development support exist?",
      answer: {
        text: "Yes! Our flexible models for post-development support maintain your application robust and on time by providing ongoing support and updating it over the years.",
      },
    },
  ],
  CMSDevelopment: [
    {
      id: 1,
      question: "What CMS platforms do you specialize in?",
      answer: {
        text: "We work with the most popular and versatile CMS platforms to meet your business needs:",
        bulletPoints: [
          "Platforms:",
          "Wordpress",
          "Joomla",
          "Drupal",
          "Shopify",
          "Magento",
          "WooCommerce",
          "Custom CMS:",
          "We also develop bespoke content management systems tailored to your business goals and user needs.",
        ],
      },
    },
    {
      id: 2,
      question: "How much does CMS development cost?",
      answer: {
        text: "The cost of CMS development depends on several factors, including:",
        bulletPoints: [
          "Platform choice (custom or pre-built CMS)",
          "Design difficulty and customization needs",
          "Features and functionality",
          "Integration with other third-party tools",
          "Drop us a line with your project's details to get a better quote.",
        ],
      },
    },
    {
      id: 3,
      question: "How long does a CMS-based website take??",
      answer: {
        text: "The time it takes to make a CMS depends on:",
        bulletPoints: [
          "Platform choice and difficulty",
          "Customization needs",
          "Other integrations with systems or tools",
          "Content migration and testing",
          "Let's talk to get an estimate that fits your needs.",
        ],
      },
    },
    {
      id: 4,
      question: "Can you help migrate my existing website to a new CMS?",
      answer: {
        text: "Yes, we can! Our CMS migration services ensure that your website is transferred to a new platform without losing data, downtime, or performance issues.",
       
      },
    },
    {
      id: 5,
      question: "Do you offer CMS customization services?",
      answer: {
        text: "Absolutely! We will tailor CMS solutions to meet your exact business needs. We design custom themes and develop unique plugins so that your CMS aligns perfectly with your objectives.",
      },
    },
    {
      id: 6,
      question: "What is your policy on intellectual property and CMS ownership?",
      answer: {
        text: "We ensure complete ownership of your CMS. From signing NDAs to handing over all source code and documentation, you retain full rights to your project.",
      },
    },
    {
      id: 7,
      question: "Do you provide post-development support for CMS?",
      answer: {
        text: "Yes, we do! Our maintenance and support services ensure your CMS remains secure, updated, and optimized for performance.",
      },
    },
  ],
  ECommerceDevelopment: [
    {
      id: 1,
      question: "What kind of e-commerce platforms do you specialize in?",
      answer: {
        text: "We make feature-rich and scalable e-commerce stores on a multitude of platforms:",
        bulletPoints: [
          "Platforms:",
          "Shopify",
          "Magento",
          "WooCommerce",
          "BigCommerce",
          "OpenCart",
          "PrestaShop",
          "Custom E-commerce:",
          "We also offer tailored e-commerce solutions to fulfill the one-of-a-kind needs of your business",
        ],
      },
    },
    {
      id: 2,
      question: "What influences the e-commerce development price?",
      answer: {
        text: "The e-commerce development cost depends on:",
        bulletPoints: [
          "Types of platforms: in-house solution or off-the-shelf solution",
          "Complexity of designs and UI/UX features",
          "Number of product lines and categories",
          "Payment gateway, shipping system, and other integrations",
          "Chatbot, AI-based tools, and analytics",
          "Tell us more about your project to get a quote.",
        ],
      },
    },
    {
      id: 3,
      question: "How long does it take to develop an e-commerce website?",
      answer: {
        text: "Estimated time will depend on:",
        bulletPoints: [
          "Design complexity and features to be included",
          "Type of platform chosen - ready-made template or a customized solution",
          "Integration with the third party systems",
          "Products' count and comprehensive testing",
          "Give us your needs, so we can give you an approximate time for that task.",
        ],
      },
    },
    {
      id: 4,
      question: "Can I have third-party systems in my e-commerce website integrated?",
      answer: {
        text: "We integrate third-party systems smoothly:",
        bulletPoints: [
          "Payment gateways: Stripe, PayPal",
          "CRM",
          "Inventory management software",
          "Shipping and logistics software",
          "Analytics and reporting software",
        ],
      },
    },
    {
      id: 5,
      question: "Do your e-commerce solutions come with mobile-friendliness?",
      answer: {
        text: "We ensure your security by implementing:",
        bulletPoints: [
          "SSL certificates",
          "Secure payment gateways",
          "GDPR and PCI-DSS compliance",
          "The latest security protocols to protect your site and customer data",
        
        ],
      },
    },
    {
      id: 6,
      question: "Do you offer post-development support and maintenance?",
      answer: {
        text: "Yes! We provide detailed post-development support to maintain your e-commerce platform updated, secure, and optimized to run at its best and get timely fixes and upgrades.",
      },
    },
    {
      id: 7,
      question: "Can you migrate my existing store to a new platform?",
      answer: {
        text: "Absolutely! Our e-commerce migration services ensure smooth transition to your desired platform with zero data loss and minimal downtime.",
      },
    },
  ],
};

export const faqDescriptions = {
  "digital-marketing": {
    description:
      "Here are some common questions and answers regarding our digital marketing services that can help boost your business.",
  },
  "quality-engineering": {
    description:
      "Below are the FAQs for our QA and software testing services that can bring answers to some of the most common questions.",
  },
  "ui-ux-design": {
    description:
      "Below are some frequently asked questions that explain how our UI/UX design services can help improve your digital experience.",
  },
  "web-development": {
    description:
      "Find the answers to your most common questions about our web development services. Get to know how we create robust and scalable websites.",
  },
  "ai-development": {
    description:
      "Explore the frequently asked questions about AI development, and learn how our solutions leverage artificial intelligence to drive innovation.",
  },
  "machine-learning": {
    description:
      "Explore the frequently asked questions about Machine Learning, and learn how our solutions leverage machine learning to drive innovation.",
  },
  "data-science": {
    description:
      "Explore the frequently asked questions about Data Science, and learn how our solutions leverage data science to drive innovation.",
  },
  "software-development": {
    description:
      "Explore the frequently asked questions about Software Development, and learn how our solutions leverage software development to drive innovation.",
  },
  "CRM-development": {
    description:
      "Explore the frequently asked questions about CRM Development, and learn how our solutions leverage CRM development to drive innovation.",
  },
  "Web-development": {
    description:
      "Explore the frequently asked questions about Web Development, and learn how our solutions leverage CRM development to drive innovation.",
  },
  "MobileAppDevelopment": {
    description:
      "Explore the frequently asked questions about Mobile App Development, and learn how our solutions leverage CRM development to drive innovation.",
  },
  "CMS-Development": {
    description:
      "Explore the frequently asked questions about CMS Development, and learn how our solutions leverage CRM development to drive innovation.",
  },
  "ECommerce-Development": {
    description:
      "Explore the frequently asked questions about ECommerce Development, and learn how our solutions leverage CRM development to drive innovation.",
  },
};
