import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {
	
	const personlcss1 = {
		padding:'5px',
   }

	const [home, setHome] = useState(false)
	const [about, setAbout] = useState(false)
	const [services, setService] = useState(false)
	const [solution, setSolution] = useState(false)
	const [blog, setBlog] = useState(false)
	const [hiredevelopers, setHiredevelopers] = useState(false)
	const [Portfolio, setPortfolio] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setAbout(false)
			setService(false)
			setBlog(false)
			setSolution(false)
			setHiredevelopers(false)
			setPortfolio(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setAbout(!about)
			setService(false)
			setBlog(false)
			setSolution(false)
			setHiredevelopers(false)
			setPortfolio(false)
		}
		else if (menu === 'services') {
			setHome(false)
			setAbout(false)
			setService(!services)
			setBlog(false)
			setSolution(false)
			setHiredevelopers(false)
			setPortfolio(false)
		}
		else if (menu === 'solution') {
			setHome(false)
			setAbout(false)
			setService(false)
			setBlog(false)
			setSolution(!solution)
			setHiredevelopers(false)
			setPortfolio(false)
			
		}
		else if (menu === 'blog') {
			setHome(false)
			setAbout(false)
			setService(false)
			setBlog(!blog)
			setSolution(false)
			setHiredevelopers(false)
			setPortfolio(false)
		}
		
		else if (menu === 'hiredevelopers') {
			setHome(false)
			setAbout(false)
			setService(false)
			setBlog(false)
			setSolution(false)
			setHiredevelopers(!hiredevelopers)
			setPortfolio(false)
		}
		else if (menu === 'Portfolio') {
			setHome(false)
			setAbout(false)
			setService(false)
			setBlog(false)
			setSolution(false)
			setHiredevelopers(false)
			setPortfolio(!Portfolio)
		}
		
	};

	return (
		<div className="container relative">
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li className={services ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="" onClick={() => { openMobileMenu('services'); }}>Service</Link>
							<ul className={services ? "sub-menu current-menu" : "sub-menu"}>
							<div className="row">
                    <div className="col-sm-12 col-md-12m">
                        <div className="main_menu_inner">
                          <h4>Digital Transformation</h4>
                          <ul>
                            <li><Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>UI/UX Design</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Digital Marketing</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Quality Engineering</Link> </li>
                            </ul>
                        </div>
                    
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className="main_menu_inner">
                          <h4>Data and AI</h4>
                          <ul>
                            <li><Link to="/" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>Artificial Intelligence (AI)</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>Machine Learning</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>Data Science</Link> </li>
                            </ul>
                        </div>
                    
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className="main_menu_inner">
                          <h4>Application Development</h4>
                          <ul>
                          <li><Link to="/web-development" lassName={activeMenu === "/web-development" ? "active-menu" : ""}>Web Development</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Software Development</Link> </li>
                            <li><Link to="/mobile-app-development" lassName={activeMenu === "/mobile-app-development" ? "active-menu" : ""}>Mobile App Development</Link> </li>
                            <li><Link to="/cms-development" lassName={activeMenu === "/cms-development" ? "active-menu" : ""}>CMS Development</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>CRM Development</Link> </li>
                            <li><Link to="/eCommerce-development" lassName={activeMenu === "/eCommerce-development" ? "active-menu" : ""}>eCommerce Development</Link> </li>
                            </ul>
                        </div>
                    
                    </div>
                </div>
							</ul>
						</li>
						<li className={solution ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="" onClick={() => { openMobileMenu('solution'); }}>Solution</Link>
							<ul className={solution ? "sub-menu current-menu" : "sub-menu"}>
							<div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="main_menu_inner">
                        <h4>By Business Scales</h4>
                          <ul>
                            <li><Link to="/web-development" lassName={activeMenu === "/web-development" ? "active-menu" : ""}>Startup Solutions</Link> </li>
                            <li><Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>Enterprise Solutionst</Link> </li>
                            </ul>
                        
                        </div>


                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className="main_menu_inner">
                        <h4>Custom Solutions</h4>
                          <ul>
                            <li><Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>DevOps Solutions</Link> </li>
                            <li><Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>Cloud Solutions</Link> </li>
                            <li><Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>On-demand Business Solutions</Link> </li>
                            </ul>
                        </div>
                    
                    </div>
                    <div className="col-sm-12 col-md-12">
                        <div className="main_menu_inner">
                         <h4>Industry Expertise</h4>
                          <ul>
                            <li><Link to="/fintech-app-solution" lassName={activeMenu === "/fintech-app-solution" ? "active-menu" : ""}>Fintech-App Solution</Link> </li>
                            <li><Link to="/business-app-solution" lassName={activeMenu === "/business-app-solution" ? "active-menu" : ""}>Business App Solutions</Link> </li>
                            <li><Link to="/ux-ui-design" lassName={activeMenu === "/ux-ui-design" ? "active-menu" : ""}>Fitness App Solutions</Link> </li>
                            <li><Link to="/fitness-wellness-app-solutions" lassName={activeMenu === "/fitness-wellness-app-solutions" ? "active-menu" : ""}>Fintech-App Solution</Link> </li>
                            <li><Link to="/travel-app-solutions" lassName={activeMenu === "/travel-app-solutions" ? "active-menu" : ""}>Travel App Solutions</Link> </li>
                            <li><Link to="/real-estate-app-solution" lassName={activeMenu === "/real-estate-app-solution" ? "active-menu" : ""}>Real Estate App Solutions</Link> </li>
                            <li><Link to="/healthcare-app-solution" lassName={activeMenu === "/healthcare-app-solution" ? "active-menu" : ""}>Healthcare App Solutions</Link> </li>
                            <li><Link to="/social-media-app" lassName={activeMenu === "/social-media-app" ? "active-menu" : ""}>Social Media App Solutions</Link> </li>
                            </ul>
                        </div>
                    
                    </div>
                </div>
							</ul>
						</li>
						<li className={hiredevelopers ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="" onClick={() => { openMobileMenu('hiredevelopers'); }}>“Hire offshore developers</Link>
							<ul className={hiredevelopers ? "sub-menu current-menu" : "sub-menu"}>
                            <div className="row">
                        <div className="col-lg-3">
                            <div className="main_menu_inner mega_menu_set">
                                <h4>Hire mobileappdevelopers</h4>
                                <ul>
                                    <li><Link to="/hire-flutter-developers" lassName={activeMenu === "/hire-flutter-developers" ? "active-menu" : ""}>Flutter Developers</Link> </li>
                                    <li><Link to="/hire-react-native-developers" lassName={activeMenu === "/hire-react-native-developers" ? "active-menu" : ""}>React Native Developers</Link> </li>
                                    <li><Link to="/hire-ios-app-developer" lassName={activeMenu === "/hire-ios-app-developer" ? "active-menu" : ""}>iOS Developers</Link></li>
                                    <li><Link to="/hire-android-app-developer" lassName={activeMenu === "/hire-android-app-developer" ? "active-menu" : ""}>Android Developers</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="main_menu_inner mega_menu_set">
                            <h4>Hire Full Stack Developers</h4>
                                <ul>
                                    <li><Link to="/hire-mean-stack-developers" lassName={activeMenu === "/hire-mean-stack-developers" ? "active-menu" : ""}>MeanStack Developers</Link> </li>
                                    <li><Link to="/hire-php-developers" lassName={activeMenu === "/hire-php-developers" ? "active-menu" : ""}>PHP Developers</Link> </li>
                                    <li><Link to="/hire-python-developers" lassName={activeMenu === "/hire-python-developers" ? "active-menu" : ""}>Python Developers</Link> </li>
                                    <li><Link to="/hire-nodejs-developers" lassName={activeMenu === "/hire-nodejs-developers" ? "active-menu" : ""}>Node.js Developers</Link> </li>
                                    <li><Link to="/hire-angular-developer" lassName={activeMenu === "/hire-angular-developer" ? "active-menu" : ""}>AngularJs Developers</Link> </li>
                                    <li><Link to="/hire-vuejs-developer" lassName={activeMenu === "/hire-vuejs-developer" ? "active-menu" : ""}>Vue Developers</Link> </li>
                                    <li><Link to="/hire-reactjs-developers" lassName={activeMenu === "/hire-reactjs-developers" ? "active-menu" : ""}>ReactJs Developers</Link> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="main_menu_inner mega_menu_set">
                            <h4>Hire functional resources</h4>
                            <ul>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>business analyst</Link></li>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>project managers</Link> </li>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>software testers.</Link> </li>
                                <li><Link to="/"  lassName={activeMenu === "/" ? "active-menu" : "" }> Hire designers</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="main_menu_inner">
                            <h4>Hire experts in</h4>
                                <ul>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Crypto</Link></li>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}> Blockcain</Link> </li>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Fintech</Link> </li>
                                <li><Link to="/hire-ux-ui-developers" lassName={activeMenu === "/hire-ux-ui-developers" ? "active-menu" : ""}>UI/UX Devloper</Link></li>
                                </ul>
                            </div>
                        </div>

                    </div>
							</ul>
						</li>
						<li className={blog ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="" onClick={() => { openMobileMenu('blog'); }}>Company</Link>
							<ul className={blog ? "sub-menu current-menu" : "sub-menu"}>
							<div className="row">
                        <div className="col-sm-12 col-ms-12">
                            <div className="main_menu_inner ">
                            <h4>About Us</h4>
                            <ul>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Our Achievement</Link> </li>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Our Leading Metaqualt</Link> </li>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Client Testimonials</Link> </li>
                                </ul>
                            </div>
                        
                        </div>
                        <div className="col-sm-12 col-ms-12">
                            <div className="main_menu_inner">
                            <h4>Careers</h4>
                            <ul>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>Current Openings</Link> </li>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}> Why Join Us</Link> </li>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}> Employee Rewards</Link> </li>
                                <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>life at metaqualt</Link> </li>
                                </ul>
                            </div>
                        
                        </div>
                        <div className="col-sm-12 col-ms-12">
                            <div className="main_menu_inner">
                            <h4>Data Mining</h4>
                            <ul>
                            <li><Link to="/blog" lassName={activeMenu === "/blog" ? "active-menu" : ""}>Blog</Link></li>
                            <li><Link to="/hire-flutter-developers" lassName={activeMenu === "/hire-flutter-developers" ? "active-menu" : ""}>   Videos</Link> </li>
                            <li><Link to="/" lassName={activeMenu === "/" ? "active-menu" : ""}>podcasts</Link></li>
                
                                </ul>
                            </div>
                        
                        </div>
                       
                    </div>

							
						
							</ul>
						</li>

						{/* <li>
							<Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
						</li> */}
						
						

						

						
						<li>
							<Link to="/Portfolio" className={activeMenu === "/Portfolio" ? "active-menu" : ""}>Portfolio</Link>
						</li>
						
                        
						

						
						
						<li style={ personlcss1} >
						<Link className="rs-get-in" to="/contact" >Get In Touch</Link>
						</li>

					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;